import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IAccountInformation } from '../../api/getAccountInformation';
import { IBeneficiary } from '../../api/getBeneficiaries';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { addBeneficiaryControls } from '../../components/BeneficiaryAdd/AddBeneficiaryControls';
import { useWindowState } from '../../Context/AccountContext/useWindowState';
import { useForm } from '../../Form/useForm/useForm';
import { setAccountInformationByIndex, updateBeneficiaryArrayByIndex } from '../../slices/accountInformation/accountInformationSlice';
import { getAccountBeneficiariesAsync, getIndividualBeneficiaryDetailsAsync, setUpdatedBeneficiaryArray, getAccountDetailsAsync, updateGotBeneficiaryDetails } from '../../slices/selectedAccountSlice/selectedAccountSlice';
import { createID } from '../../utils/createId';
import { populateAccountDetails } from '../../utils/populateAccountDetails';

/**
 * The cutom hook is used to repopulate the selectedAccount and the beneficiaries state on the context.
 * This hook is necessary for when a user refreshes the Beneficiaries page.
 */
export const useBeneficiaryPage = () => {
  const { windowState } = useWindowState()
  const history = useHistory()
  const [editBeneficiary, setEditBeneficiary] = useState<boolean>(false)
  const [editProfile, setEditProfile] = useState<boolean>(false)
  const [addBeneficiary, setAddBeneficiary] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [deleteBeneficiary, setDeleteBeneficiary] = useState<boolean>(false)
  const [checkAllocations, setCheckAllocations] = useState<boolean>(false)
  const childRef = useRef<any>()
  const [showAlertMessage, setShowAlertMessage] = useState<"Deleted Success" | "Deleted Failed" | "Updated Success" |
    "Updated Failed" | "New Success" | "New Failed" | "Add Success" | "Add Failed" | "">("")
  const defaultBeneficiary: IBeneficiary = {
    id: createID(),
    name: "",
    percentage: 0,
    tin: '',
    address1: '',
    city: '',
    state: '',
    zipCode: '',
    type: '',
    isDeleted: false
  };
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [newBeneficiary, setNewBeneficiary] = useState<IBeneficiary>(defaultBeneficiary);
  const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
  const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
  const startBeneficiaryFlow = useAppSelector((state: RootState) => state.selectedAccount.startBeneficiaryFlow)
  const dispatch = useAppDispatch();
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<IBeneficiary>(selectedAccount.beneficiaries[0])
  const adjustedUrl = window.location.pathname.split('/')[2];
  const [_, setGotBeneficiaryData] = useState<boolean>(false)
  const [form, dispatchForm] = useForm('addBeneficiaryForm', addBeneficiaryControls())


  const updateAccountInformationArray = (tempBeneficiaryArray: Array<IBeneficiary>) => {
    const index = accountInformation.findIndex((account: IAccountInformation) => account.id === selectedAccount.id)
    const payload = { beneficiaryArray: tempBeneficiaryArray, arrayIndex: index }
    dispatch(updateBeneficiaryArrayByIndex(payload))
  }


  const getSingleBeneficiary = async (beneficiary: IBeneficiary) => {
    const apiData = { accountNumber: selectedAccount.id, beneficiaryId: beneficiary.id as string, type: beneficiary.type, accountInformation: accountInformation }
    const details: any = await dispatch(getIndividualBeneficiaryDetailsAsync(apiData))
    try {
      let tempBeneficiary: IBeneficiary = beneficiary;
      if (beneficiary.type === "Person") {
        tempBeneficiary = {
          id: beneficiary.id,
          name: beneficiary.name,
          percentage: beneficiary.percentage,
          type: beneficiary.type,
          relationship: details.payload.relationship ? details.payload.relationship : "",
          firstName: details.payload.firstName ? details.payload.firstName : "",
          middleName: details.payload.middleName ? details.payload.middleName : "",
          lastName: details.payload.lastName ? details.payload.lastName : "",
          ssn: details.payload.taxIdentificationNumber ? details.payload.taxIdentificationNumber : "",
          dateOfBirth: details.payload.birthDate ? details.payload.birthDate : "",
          address1: details.payload.addressLine1 ? details.payload.addressLine1 : "",
          address2: details.payload.addressLine2 ? details.payload.addressLine2 : "",
          city: details.payload.city ? details.payload.city : "",
          state: details.payload.state ? details.payload.state : "",
          zipCode: details.payload.postCode ? details.payload.postCode : "",
          phoneNumber: details.payload.phoneNumber ? details.payload.phoneNumber : "",
          email: details.payload.emailAddress ? details.payload.emailAddress : "",
          isDeleted: false
        }
      }
      else if (beneficiary.type === "Trust") {
        tempBeneficiary = {
          id: beneficiary.id,
          percentage: beneficiary.percentage,
          type: beneficiary.type,
          name: details.payload.name ? details.payload.name : "",
          trustName: details.payload.name ? details.payload.name : "",
          trustEstablishedDate: (details.payload.establishedDate && details.payload.establishedDate !== "1900-01-01") ? details.payload.establishedDate : "",
          phoneNumber: details.payload.phoneNumber ? details.payload.phoneNumber : "",
          email: details.payload.emailAddress ? details.payload.emailAddress : "",
          tin: details.payload.taxIdentificationNumber ? details.payload.taxIdentificationNumber : "",
          address1: details.payload.addressLine1 ? details.payload.addressLine1 : "",
          address2: details.payload.addressLine2 ? details.payload.addressLine2 : "",
          city: details.payload.city ? details.payload.city : "",
          state: details.payload.state ? details.payload.state : "",
          zipCode: details.payload.postCode ? details.payload.postCode : "",
          isDeleted: false
        }
      }
      else if (beneficiary.type === "Charity" || beneficiary.type === "Non Profit") {
        tempBeneficiary = {
          id: beneficiary.id,
          percentage: beneficiary.percentage,
          type: beneficiary.type,
          charityName: details.payload.name ? details.payload.name : "",
          name: details.payload.name ? details.payload.name : "",
          phoneNumber: details.payload.phoneNumber ? details.payload.phoneNumber : "",
          email: details.payload.emailAddress ? details.payload.emailAddress : "",
          tin: details.payload.taxIdentificationNumber ? details.payload.taxIdentificationNumber : "",
          address1: details.payload.addressLine1 ? details.payload.addressLine1 : "",
          address2: details.payload.addressLine2 ? details.payload.addressLine2 : "",
          city: details.payload.city ? details.payload.city : "",
          state: details.payload.state ? details.payload.state : "",
          zipCode: details.payload.postCode ? details.payload.postCode : "",
          isDeleted: false
        }
      }
      return Promise.resolve(tempBeneficiary)
    }
    catch {
      console.error()
      return Promise.reject(false);
      // tempBeneficiaryArray.push(selectedAccount.beneficiaries[i])
    }
  }

  const dispatchBeneficiaries = (beneficiaries: Array<IBeneficiary>) => {
    beneficiaries = beneficiaries.sort((a, b) => {
      if (a.percentage > b.percentage) {
        return -1
      }
      if (a.percentage < b.percentage) {
        return 1
      }
      return 0
    })
    dispatch(setUpdatedBeneficiaryArray(beneficiaries))
    dispatch(updateGotBeneficiaryDetails(true))
    updateAccountInformationArray(beneficiaries)
  }

  /**Gets the beneficiary details for each beneficiary in the selectedAccount.beneficiaries array */
  const getAccountBeneficiaryDetails = async (beneficiaries: any) => {
    let tempBeneficiaryArray: Array<IBeneficiary> = [];
    let returnedBeneficiaryCount = 0;
    for (let i = 0; i < beneficiaries.length; i = i + 1) {
      getSingleBeneficiary(beneficiaries[i])
        // eslint-disable-next-line no-loop-func
        .then((res) =>  {
          returnedBeneficiaryCount = returnedBeneficiaryCount + 1;
          tempBeneficiaryArray.push(res)
          if(returnedBeneficiaryCount === beneficiaries.length && tempBeneficiaryArray.length) {
            dispatchBeneficiaries(tempBeneficiaryArray)
          }
        })
        // eslint-disable-next-line no-loop-func
        .catch(() => {
          returnedBeneficiaryCount += 1;
          if(returnedBeneficiaryCount === beneficiaries.length && tempBeneficiaryArray.length) {
            dispatchBeneficiaries(tempBeneficiaryArray)
          }
        })
    }
  }

  // useEffect(() => {
  //   if(selectedAccount.gotBeneficiaryDetails && selectedBeneficiary){
  //     const arrayIndex = selectedAccount.beneficiaries.findIndex((beneficiary: IBeneficiary) => beneficiary.id === selectedBeneficiary.id)
  //     if(arrayIndex >= 0){
  //       setSelectedBeneficiary(selectedAccount.beneficiaries[arrayIndex])
  //       form.overrideControlValues(selectedAccount.beneficiaries[arrayIndex])
  //       dispatchForm()
  //     }
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[selectedAccount.gotBeneficiaryDetails, selectedBeneficiary])

  /**Handles the onClick event to enter edit beneficiary details */
  const handleOnClick = (beneficiary: IBeneficiary) => {
    setSelectedBeneficiary(beneficiary);
    form.overrideControlValues(beneficiary)
    dispatchForm()
    dispatchForm()
    dispatchForm()
  }

  /**Calls to get beneficiary details.  This happens when the beneficiary array is already populated with basic beneficiary data on render */
  useEffect(() => {
    if (selectedAccount.beneficiaries.length) {
      getAccountBeneficiaryDetails(selectedAccount.beneficiaries)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * API Call to get Beneficiary data on page refresh, if the API call is successful then
   * the state gotBeneficiaryData will be set to true
   */
  const getAccountBeneficiaryData = async () => {
    const response: any = await dispatch(getAccountBeneficiariesAsync(adjustedUrl))
    try {
      getAccountBeneficiaryDetails(response.payload.beneficiaries)
      setGotBeneficiaryData(true)
    }
    catch {
      console.error(response)
    }
  }

  /**
   * The useEffect calls the function if the conditons are correct to repopulate the data on page refresh. 
   * If the basic beneficiary data already exists, then the state gotBeneficiaryData will be set to true, otherwise
   * it will be retrieved.
   */
  useEffect(() => {
    /**Get the selected account details again if it is missing due to page refresh */

    if (selectedAccount.id === "" && accountInformation[0].id !== "") {
      const adjustedUrl = window.location.pathname.split('/')[2]

      const index = accountInformation.findIndex((account: any) => adjustedUrl === account.id)
      if (index >= 0) {
        const account = accountInformation[index]
        repopulateAccountDetails(index, account)
      }
      else {
        history.push("/")
      }
    }
    if (!selectedAccount.beneficiaries.length && !selectedAccount.isBeneficiariesLoaded && selectedAccount.id !== "" && accountInformation[0].id !== "") {
      getAccountBeneficiaryData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInformation])

  /**Repopulates the account details on a page refresh */
  const repopulateAccountDetails = async (index: number, account: any) => {
    const payload = { arrayIndex: index, account: account }
    const response: any = await dispatch(getAccountDetailsAsync(payload))
    try {
      let tempAccount = populateAccountDetails(accountInformation[index], response.payload.response)
      const payload = { arrayIndex: index, account: tempAccount }
      dispatch(setAccountInformationByIndex(payload))
    }
    catch {
      console.error()
    }
  }

  /**
   * Checks to see if the add beneficiary flow should begin
   */
  useEffect(() => {
    if (startBeneficiaryFlow) {
      setAddBeneficiary(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    editBeneficiary, setEditBeneficiary, editProfile, setEditProfile, selectedBeneficiary, setSelectedBeneficiary, windowState,
    deleteBeneficiary, setDeleteBeneficiary, showAlertMessage, setShowAlertMessage, addBeneficiary, setAddBeneficiary, selectedAccount,
    unsavedChanges, setUnsavedChanges, childRef, checkAllocations, setCheckAllocations, currentPage, setCurrentPage, newBeneficiary, setNewBeneficiary,
    form, dispatchForm, handleOnClick
  }
}
