import { useEffect, useState } from 'react';
import { IBeneficiary, patchBeneficiaryAllocations_API, postNewBeneficiaryOrg_API, postNewBeneficiaryPerson_API } from '../../../api/getBeneficiaries';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { addBeneficiaryToArray,  } from '../../../slices/selectedAccountSlice/selectedAccountSlice';
import { addBeneficiaryByIndex, } from '../../../slices/accountInformation/accountInformationSlice'
import { createID } from '../../../utils/createId';
import { IFormContext, TControlDispatch } from '../../../Form/useForm/useForm';

/**
 * useHook for add beneficiary form
 */
export const useAddBeneficiary = (
  setDisplayAddBeneficiary: React.Dispatch<React.SetStateAction<boolean>>,
  setEditBeneficiary: React.Dispatch<React.SetStateAction<boolean>>,
  setShowAlertMessage: React.Dispatch<
    React.SetStateAction<
      | ''
      | 'Deleted Success'
      | 'Deleted Failed'
      | 'Updated Success'
      | 'Updated Failed'
      | 'New Success'
      | 'New Failed'
      | 'Add Success'
      | 'Add Failed'
    >
  >,
  currentPage: number,
  setCurrentPage: any,
  newBeneficiary: IBeneficiary,
  setNewBeneficiary: any,
  form: IFormContext,
  dispatchForm: TControlDispatch
) => {
  const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
  const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
  const dispatch = useAppDispatch()
  const defaultBeneficiary: IBeneficiary = {
    name: "",
    id: createID(),
    percentage: 0,
    tin: '',
    address1: '',
    city: '',
    state: '',
    zipCode: '',
    type: '',
    isDeleted: false
  };
  const pages = ['SelectType', 'RequiredInformation', 'ReviewInformation', 'Allocations', 'Confirmation'];
  const [showBack, setShowBack] = useState(false);
  
  /**
   * advances the page on form
   */
  const handleOnNext = async () => {
    if(pages[currentPage] === "ReviewInformation"){
      await dispatch(addBeneficiaryToArray(newBeneficiary))
    }
    if(selectedAccount.beneficiaries.length > 0){
      setCurrentPage(currentPage + 1);
    }
    else{
      setCurrentPage(currentPage + 1)
    }
  };

  /**
   * Cancels form and it is no longer displayed
   */
  const handleOnCancel = () => {
    setCurrentPage(0);
    setDisplayAddBeneficiary(false);
    setNewBeneficiary(defaultBeneficiary);
    form.resetForm()
    dispatchForm()
  };

  /**
   * goes back in form
   */
  const handleOnBack = () => {
    // resets beneficiary when the user goes back to select a type
    if (pages[currentPage] === 'RequiredInformation') {
      setNewBeneficiary(defaultBeneficiary);
      form.resetForm()
      dispatchForm()
    }
    //?This is only on mobile for now until we get more clerification
    // if (pages[currentPage] === 'SelectType') {
    //   handleOnCancel();
    // }
    setCurrentPage(currentPage - 1);
  };

  /**
   * upon successful submition, API will be called to add beneficiary
   * @param updatedBeneficiary beneficiary to be added
   */
  const handleOnFinish = async (updatedBeneficiary: IBeneficiary) => {
    let tempBeneficiary = updatedBeneficiary
    if(tempBeneficiary.type === "Person"){
      try{
        let response = await postNewBeneficiaryPerson_API(selectedAccount.id, tempBeneficiary, accountInformation)
        tempBeneficiary = {...tempBeneficiary, id: response.data.id}
        if(selectedAccount.beneficiaries.length === 0){
          // const apiPayload = {accountId: selectedAccount.id, beneficiaries: [tempBeneficiary]}
          // dispatch(patchBeneficiaryAllocations(apiPayload))
          try{
            await patchBeneficiaryAllocations_API(selectedAccount.id, [tempBeneficiary], accountInformation)
          }
          catch{
            console.log("FAILED TO UPDATE THE ALLOCATIONS")
          }
        }      
        dispatch(addBeneficiaryToArray(tempBeneficiary))
        const index = accountInformation.findIndex((account: any) => account.id === selectedAccount.id)
        const payload = {arrayIndex: index, beneficiary: tempBeneficiary}
        dispatch(addBeneficiaryByIndex(payload))
        setCurrentPage(currentPage + 2);
      }
      catch{
        console.log('ERROR ADDING THE NEW BENEFICIARY')
        return Promise.reject(false)
      }
    }
    else {
      try{
        // let response = await dispatch(postNewBeneficiaryOrgAsync(apiPayload))
        let response = await postNewBeneficiaryOrg_API(selectedAccount.id, tempBeneficiary, accountInformation)
        tempBeneficiary = {...tempBeneficiary, id: response.data.id}
        // console.log('tempm', tempBeneficiary)
        if(selectedAccount.beneficiaries.length === 0){
          // const apiPayload = {accountId: selectedAccount.id, beneficiaries: [tempBeneficiary]}
          // dispatch(patchBeneficiaryAllocations(apiPayload))

          try{
            await patchBeneficiaryAllocations_API(selectedAccount.id, [tempBeneficiary], accountInformation)
          }
          catch{
            console.log("FAILED TO UPDATE THE ALLOCATIONS")
          }
        }    
        dispatch(addBeneficiaryToArray(tempBeneficiary))
        const index = accountInformation.findIndex((account: any) => account.id === selectedAccount.id)
        const payload = {arrayIndex: index, beneficiary: tempBeneficiary}
        dispatch(addBeneficiaryByIndex(payload)) 
        setCurrentPage(currentPage + 2);
      }
      catch{
        console.log("ERROR ADDING THE NEW BENEFICIARY")
        return Promise.reject(false)
      }
    }
    setNewBeneficiary(defaultBeneficiary);
    form.resetForm()
    dispatchForm()
    return Promise.resolve(true)
  };

  /** only shows back button when form is passed the first page */
  useEffect(() => {
    if (currentPage === 0) {
      setShowBack(false);
    } else {
      setShowBack(true);
    }
  }, [currentPage]);

  return {
    selectedAccount,
    pages,
    currentPage,
    showBack,
    setShowBack,
    newBeneficiary,
    setNewBeneficiary,
    handleOnBack,
    handleOnCancel,
    handleOnNext,
    handleOnFinish,
    setCurrentPage
  };
};
