import { useRef, useState } from "react";
import { IControl } from "../Form/Control/IControl.interface";
import { IFormContext, TControlDispatch } from "../useForm/useForm";

/**A custom use hook holding the logic for the Number Input */
export const useNumberInput = (form: IFormContext, control: IControl, dispatchControlChanges: TControlDispatch) => {
    const zipCode = useRef<string>("")
    const lastKeyRef = useRef<string>("")
    const numberInputRef = useRef(document.createElement('input'))

    /**Handles the onKeyDown event for the Number Input */
    const handleKeyDown = (e: any) => {
        const isDecimal = new RegExp(/[0-9]/)
        if ((!e.ctrlKey && e.key !== "v") && !isDecimal.test(e.key) && e.key !== "Backspace" && e.key !== 'Delete' && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== 'Tab' && e.key !== "-") {
            e.preventDefault()
        }
        else{
            lastKeyRef.current = e.key
        }
    }

    /**Checks to see where the dashes should be shown for the zip code */
    const checkDashes = (value: string) => {
        let newValue = ""
        const [left, right] = value.split("-")
        const temp = (left || "") + (right || "")
        for(let i = 0; i < temp.length; i = i + 1){
            newValue = newValue + temp[i]
            if(i === 4 && i < temp.length - 1){
                newValue = newValue + "-"
            }
        }

        numberInputRef.current.value = newValue
        return newValue
    }

    /**Sets the correct cursor position to be used with zipCode changes events*/
    const setCursorPosition = (startPosition: number) => {
        if(lastKeyRef.current === "Backspace" || lastKeyRef.current === "Delete"){
            if(startPosition === 5 && lastKeyRef.current !== "Backspace" && lastKeyRef.current !== "Delete"){
                numberInputRef.current.setSelectionRange(startPosition +2, startPosition +2)
            }
            else if(startPosition === 6 && lastKeyRef.current === "Backspace"){
                numberInputRef.current.setSelectionRange(startPosition, startPosition)
            }
            else{
                numberInputRef.current.setSelectionRange(startPosition, startPosition)
            }
        }
        else{
            if(startPosition === 6){
                numberInputRef.current.setSelectionRange(startPosition +1, startPosition +1)
            }
            else{
                numberInputRef.current.setSelectionRange(startPosition, startPosition)
            }
        }
    }


    /**Handles the onChange event for the Number Input */
    const onChangeHandler = (e: any) => {
        if(control.name === "zipCode"){
            let startPosition = numberInputRef.current.selectionStart || 0
            const FORMATED_VALUE = checkDashes(e.target.value)
            zipCode.current = FORMATED_VALUE
            setCursorPosition(startPosition)
            form.setValue(control.name, zipCode.current)
        }
        else{
            form.setValue(control.name, e.target.value)
        }
        /**If there are error than run validations on the control */
        if (control.errors.length) {
            form.runValidations(control.name)
        }
        dispatchControlChanges()
    }

    /**Handles the onBlur event for the Number Input */
    const handleOnBlur = () => {
        form.runValidations(control.name)
        dispatchControlChanges()
    }

    /**Handles copy and then paste event into the number input */
    const handleOnPaste = (e: any) => {
        const format = new RegExp("^[0-9]{5}(-[0-9]{4})?$")
        zipCode.current = e.clipboardData.getData("text/plain")
        if(!format.test(zipCode.current)){
            e.preventDefault()
        }
        else{
            form.setValue(control.name, zipCode.current.slice(0, 5))        
            form.runValidations(control.name)
            dispatchControlChanges()
            dispatchControlChanges()
            e.preventDefault()
        }
    }

    return { onChangeHandler, handleOnBlur, handleKeyDown, handleOnPaste, numberInputRef }
}