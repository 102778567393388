import { Control } from "../../../../Form/Form/Control/Control";
import { VALIDATORS } from "../../../../Form/Form/Validators/Validators";

/**Controls array for adding beneficiary of 'Person' type */
export const addAddressControls = () => {

    const addressLine1 = Control('addressLine1', '', 'Address', 'input',
        {
            validations: [VALIDATORS.isRequired()],
            errorMessages: {
                IS_REQUIRED: 'This field is required',
                NO_PO_BOX: 'Please enter a street address'
            },
            placeholder: "Street address and number"
        }
    );

    const addressLine2 = Control('addressLine2', '', 'Apartment or unit number (optional)', 'input', {
        errorMessages: {
            NO_PO_BOX: 'Please enter a street address'
        },
        placeholder: "Apartment, suit, unit, building, etc"
    });

    const city = Control('city', '', 'City', 'input',
        {
            validations: [VALIDATORS.isRequired()],
            errorMessages: {
                IS_REQUIRED: 'This field is required'
            },
            placeholder: "City"
        }
    );

    const state = Control('state', '', 'State', 'select',
        {
            validations: [VALIDATORS.isRequired()],
            errorMessages: {
                IS_REQUIRED: 'This field is required'
            },
            placeholder: "Select State",
            selectedOptions: ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA",
                "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK",
                "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]
        }
    );

    const zipCode = Control('zipCode', '', 'Zip code', 'number',
        {
            validations: [VALIDATORS.isRequired(), VALIDATORS.zipCodeFormat(5)],
            errorMessages: {
                IS_REQUIRED: 'This field is required',
                ZIP_CODE_FORMAT: `Please enter a 5-digit ZIP code`
            },
            placeholder: "e.g. 89381"
        }
    );

    return ([
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode,
    ]
    );
}
