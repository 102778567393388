import axios, { AxiosPromise } from "axios"
import { bffBaseUrl, token } from "../utils/globalVariables"
import { UpdateAccountNickNameResponse } from './models'
import { RootState } from "../app/store"
import { IAccountInformation } from "./getAccountInformation"

//!ISSUE
/**An API call to update the nickname for a particular account */
export const patchAccountNickName_API = (accountNumber: string, newNickName: string, accountInformation: Array<IAccountInformation>) => {
    let id = ""

    const index = accountInformation.findIndex((account: IAccountInformation) => account.id === accountNumber)
    if(index >= 0){
        id = accountInformation[index].id
    }

    return axios.patch(`${bffBaseUrl}/accounts/${id}`,
        {
            accountNickName: newNickName,
        },
        {
            headers: {
                Authorization: `Bearer ` + token,
                'Content-Type': 'application/json'
            }
        }
    )
}