import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { bffBaseUrl, token } from '../utils/globalVariables';
import { IAccountInformation } from './getAccountInformation/types/getAccountInformation.types';
import { mockbasicAccountInfo } from './getAccountInformation/mocks/mockBasicAccountInfo';
import { IBeneficiary } from './getBeneficiaries';
import { IStatements } from './getStatements';
import { AccountListResponse } from './models';
import { RootState } from '../app/store';

export type { IAccountInformation, IOwner, ISelectedInterestAccount, ITaxDocuments, IAccountAgreements, IAccountNotices, IFundTransfer, IEarlyWithdrawal, IGracePeriodFundTransfer, IProduct, IGetAccountInformationResponse } from './getAccountInformation/types/getAccountInformation.types';

export { mockbasicAccountInfo } from './getAccountInformation/mocks/mockBasicAccountInfo';


/**Populate the selectedAccount details with API return data*/
export const populateSelectedAccountDetails = (
    response: any, 
    account: IAccountInformation, 
    beneficiaries: Array<IBeneficiary>,
    statements: Array<IStatements>
  ) => {
  let tempAccount = JSON.parse(JSON.stringify(account))

  tempAccount.beneficiaries = beneficiaries
  tempAccount.accountStatements = statements
  tempAccount.accountTitle = response.accountTitle
  tempAccount.description = response.accountName
  tempAccount.interestPaid = response.interestPaidAccountToDate
  tempAccount.interestPaidPriorYear = response.interestPaidPriorYear
  tempAccount.routingNumber = response.routingNumber
  tempAccount.owner = response.ownershipDetails
  tempAccount.term = `${response.maturitySettings.termLength} months`
  tempAccount.maturityDate = response.maturitySettings.matureOn
  tempAccount.interestPaidYTD = response.interestPaidYTD
  tempAccount.maturitySetting.termLength = response.maturitySettings.termLength
  tempAccount.maturitySetting.changeProduct = `${response.rollOverMaturity.productGroup} ${response.rollOverMaturity.changeProduct?.toLowerCase()}` || ""
  //! Add the bank account name to the transfer account when a bank name gets returned
  tempAccount.maturitySetting.transferAccount = response.transfer ? response.transfer.bankAccountName + " " + response.transfer.bankAccountNumberLastFourDigit : ""
  tempAccount.maturitySetting.transferAmount = response.transfer ? response.transfer.amount : ""
  tempAccount.maturitySetting.transferType = response.transfer && response.transfer.payment ? "Withdraw" : "Add"
  tempAccount.maturitySetting.scheduledTransferId = response.transfer && response.transfer.id
  tempAccount.maturitySetting.option = response.rollOverMaturity.rollPlan
  tempAccount.maturitySetting.closedAccountName = response.rollOverMaturity.rollPlan === "Close" ? `${response.rollOverMaturity.bankName} ${response.rollOverMaturity.counterPartyAcctNumber}` : ""
  tempAccount.maturitySetting.changeProductCode = response.rollOverMaturity.productCode
  // tempAccount.maturitySetting.closedAccountName = response.rollOverMaturity.bankName
  tempAccount.graceTermRenewal = {
    cdGroup: account.cdGroup,
    cdTerm: response.maturitySettings.termLength,
    apy: String(response.apy),
    productCode: ""
  }
  tempAccount.earlyWithdrawalPenalty = response.penaltyAmount || ""

  return tempAccount
}

const sortAccounts = (adjustedAccounts: any) => {
  if(adjustedAccounts && adjustedAccounts.length > 0){
    let sortedAccounts: Array<any> = []
    sortedAccounts = adjustedAccounts.sort((a: any, b: any) => {
      if(!a.accountClosed && b.accountClosed){
        return -1
      }
      else if(a.accountClosed && !b.accountClosed){
        return 1
      }
      else{
        return 1
      }
    })
    return sortedAccounts;
  }
  return adjustedAccounts
}


/**
 * Creating the AccountInformation object to be passed through the project.
 */
export const createAccountInformationObject = (accounts: any) => {
  let adjustedAccounts: Array<any> = [];
  let mockData = mockbasicAccountInfo.data[0];
  let sortedAccounts: Array<any> = []
  if (accounts) {
    for (let i = 0; i < accounts.accounts.length; i = i + 1) {
      adjustedAccounts[i] = {
        id: accounts.accounts[i].id,
        accountNumber: accounts.accounts[i].accountNumber,
        accountTitle: accounts.accounts[i].accountNickName,
        accountType: accounts.accounts[i].accountType,
        balance: accounts.accounts[i].availableBalance || 0,
        accountStatements: [],
        taxDocuments: [],
        accountAgreements: [],
        accountNotices: [],
        interestRate: accounts.accounts[i].apy,
        beneficiaries: [],
        interestAccount: accounts.accounts[i].accountNumber,
        maturitySetting: JSON.parse(JSON.stringify(mockData.maturitySetting)),
        selectedInterestAccount: { id: accounts.accounts[i].id, accountNumber: accounts.accounts[i].accountNumber, accountType: accounts.accounts[i].accountType, accountTitle: accounts.accounts[i].accountNickName },
        statements: [],
        isBeneficiariesLoaded: false,
        gotBeneficiaryDetails: false,
        creationDate: accounts.accounts[i].createdDate,
        graceEndDate: accounts.accounts[i].creditGracePeriod || "",
        graceStartDate: accounts.accounts[i].lastRollOverDate || "",
        isGracePeriod: accounts.accounts[i].isInGracePeriod || false,
        earlyWithdrawal: accounts.accounts[i].earlyWithdrawal || undefined,
        earlyWithdrawalSelected: accounts.accounts[i].earlyWithdrawalSelected || false,
        gracePeriodFundTransfer: JSON.parse(JSON.stringify(mockData.gracePeriodFundTransfer)),
        accountClosed: accounts.accounts[i].status === "Closed" ? true : false,
        accountClosedDate: accounts.accounts[i].closedDate,
        transactions: [],
        isTransactionsLoaded: false,
        bumpActivated: accounts.accounts[i].bumpDate ? true : false,
        isBannerAvailable: accounts.accounts[i].isBannerAvailable || false,
        bumpedRateDate: accounts.accounts[i].bumpDate || "",
        bumpedRate: accounts.accounts[i].bumpApy,
        originalApy: accounts.accounts[i].originalApy,
        isBumpAvailable: accounts.accounts[i].isBumpEnabled || false,
        maturityDate: accounts.accounts[i].maturityDate,
        cdGroup: accounts.accounts[i].productGroup,
        currentBalance: accounts.accounts[i].currentBalance || 0,
        earlyWithdrawalPenalty: "0",
        loyaltyRate: accounts.accounts[i].rewardActive || false,
        pendingGraceClosure: accounts.accounts[i].status === "Pending Closure" ? true : false
      };
    }
    
    sortedAccounts = sortAccounts(adjustedAccounts)
    return sortedAccounts
  }
  return accounts
};


/**
 * get api call for account information
 * @param customerId string
 * @returns Account Information object
 */
export const getAccountInformation_API = (customerId: string) => {
  return axios.get(`${bffBaseUrl}/accounts`) as AxiosPromise<AccountListResponse>;
};

/**Gets the account number for a particular account */
export const getAccountNumber_API = (accountNumber: string) => {
  return axios.get(`${bffBaseUrl}/accounts/${accountNumber}/accountNumber`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

/**Gets the account details for a particular account */
export const getAccountDetails_API = (accountId: string) => {
  return axios.get(`${bffBaseUrl}/accounts/${accountId}`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

/**API Call to get the list of products */
export const getProductList_API = () => {
  return axios.get(`${bffBaseUrl}/products`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

/**API Call to get interest settings */
export const getInterestSettings_API = (accountId: string) => {
  return axios.get(`${bffBaseUrl}/accounts/${accountId}/interestpayments`)
}

/**API Call to get the users tax documents */
export const getTaxDocuments_API = () => {
  return axios.get(`${bffBaseUrl}/customers/documents?docType=TAX`)
}

/**API Call to get account statements */
export const getAccountStatements_API = (accountId: string, dateFilter: string, accountInformation: Array<IAccountInformation>) => {
  let id = ""

  const index = accountInformation.findIndex((account: IAccountInformation) => account.id === accountId)
  if(index >= 0){
    id = accountInformation[index].id
  }

  return axios.get(`${bffBaseUrl}/accounts/${id}/documents?docType=statements&year=${dateFilter}`)
}

/**API Call to get account notices and other documents */
export const getAccountNotices_API = (accountId: string) => {
  return axios.get(`${bffBaseUrl}/accounts/${accountId}/documents?docType=Notifications&year=All`)
}

/**API Call to get account agreements */
export const getAccountAgreements_API = (accountId: string) => {
  return axios.get(`${bffBaseUrl}/accounts/${accountId}/terms-and-conditions`)
}

/**API Call to download an account statement */
export const downloadAccountStatement = (accountId: string, documentId: string, accountInformation: Array<IAccountInformation>) => {
  let id = ""

  const index = accountInformation.findIndex((account: IAccountInformation) => account.id === accountId)
  if(index >= 0){
    id = accountInformation[index].id
  }
  return axios.get(`${bffBaseUrl}/accounts/${id}/documents/${documentId}`,
    {
      responseType: 'blob'
    }
  )
}

/**API Call to download a tax document, the year in format yyyy or for example 2023 */
export const downloadTaxDocument = (documentID: string) => {
  return axios.get(`${bffBaseUrl}/documents/tax/${documentID}`,
    {
      responseType: "blob"
    }
  )
}

/**API Call to download an account Notices & other document */
export const downloadAccountNotice = (accountId: string, documentId: string, accountInformation: Array<IAccountInformation>) => {
  let id = ""

  const index = accountInformation.findIndex((account: IAccountInformation) => account.id === accountId)
  if(index >= 0) {
    id = accountInformation[index].id
  }

  return axios.get(`${bffBaseUrl}/accounts/${id}/documents/${documentId}`,
    {
      responseType: "blob"
    }
  )
}

/**API Call to download an account agreement */
// export const downloadAccountAgreement = (accountId: string, documentId: string) => {
//   return axios.get(`${bffBaseUrl}/accounts/${accountId}/documents/${documentId}`,
//     {
//       responseType: "blob"
//     }
//   )
// }