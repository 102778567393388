import { useNumberInput } from '../../Form/useHooks/useNumberInput';
import InputError from '../InputError/InputError';
import InputLabel from '../InputLabel/InputLabel';
import { INumberInputProps } from './interfaces/INumberInputProps'
import './NumberInput.scss'

/**Renders a number type input box */
const NumberInput = (props: INumberInputProps) => {
    const { control, className, style = {}, form, dispatchControlChanges } = props;
    const { onChangeHandler, handleOnBlur, handleKeyDown, handleOnPaste, numberInputRef } = useNumberInput(form, control, dispatchControlChanges)
    
    return(
        <div className={`number-input-wrap ${className}`}>
            <InputLabel control={control} >
                <input 
                    data-testid={control.name}
                    type="text" 
                    ref={numberInputRef}
                    placeholder={control.isDisabled ? '' : control.placeholder} 
                    className={control.errors.length > 0 ? "pass error regular" : "pass regular"} 
                    id={control.id} 
                    style={style}
                    onChange={(e: any) => onChangeHandler(e)} 
                    onBlur={() => handleOnBlur()} 
                    onPaste={(e: any) => handleOnPaste(e)}
                    onKeyDown={(e) => handleKeyDown(e)} 
                    maxLength={control.name === "zipCode" ? 5 : 10}
                    disabled={control.isDisabled}
                    value={control.value}
                />
            </InputLabel>
            {control.errors.length > 0 && <InputError controlName={control.name} errorArray={control.errors} getErrorMessage={form.getErrorMessage} />}
        </div>
    )
}

export default NumberInput