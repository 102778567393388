import { IControl } from "../Control/IControl.interface"
import { IValidator } from "./IValidator.interface"

export const zipCodeFormatValidator = (length: number):IValidator => ({
    name: 'ZIP_CODE_FORMAT',
    value: length
})

export const ZIP_CODE_FORMAT = (control: IControl, validator: IValidator) => {
    let newValue = control.value
    const TYPE_OF = typeof newValue

    if(!['number', 'string'].includes(TYPE_OF)) {
        throw new Error(`Zip Code Format validator on ${control.name} needs to be typeof number.  The value is a ${typeof newValue}`)
    }

    const VALUE_LENGTH = (newValue as number).toString().length
    const format = new RegExp("^[0-9]{5}(-[0-9]{4})?$")
    if(format.test(newValue) && (VALUE_LENGTH === 5 || VALUE_LENGTH === 10)){
        return false
    }

    return {...validator, actual:VALUE_LENGTH}
}