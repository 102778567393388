import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { IStatements } from "../../api/getStatements"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { useWindowState } from "../../Context/AccountContext/useWindowState"
import { setAccountInformationByIndex, updateAccountStatementsByIndex } from "../../slices/accountInformation/accountInformationSlice"
import { getAccountDetailsAsync, getAccountStatementsAsync } from '../../slices/selectedAccountSlice/selectedAccountSlice'
import { populateAccountDetails } from "../../utils/populateAccountDetails"
import { populateStatements } from "../../utils/populateStatements"

/**A custom hook for the statements page use by both Desktop and Mobile */
export const useStatementsPage = () => {
    const { windowState } = useWindowState()
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const [filterYear, setFilterYear] = useState<string>("Any")
    const [filteredStatements, setFilteredStatements] = useState<Array<IStatements>>(selectedAccount.statements)
    const [showPDF, setShowPDF] = useState<boolean>(false)
    const statementYearsRef = useRef<Array<string>>([])
    const [openFilterMenu, setOpenFilterMenu] = useState<boolean>(false)
    const [tempSelectedYear, setTempSelectedYear] = useState<string>("Any")
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [ startIndex, setStartIndex ] = useState<number>(0)    
    const [endIndex, setEndIndex] = useState<number>(10)
    const [numberOfItemsToShow, setNumberOfItemsToShow] = useState(10);  
    const arrayIndexRef = useRef<number>(0)         
    const gotStatements = useAppSelector((state: RootState) => state.selectedAccount.gotStatements)

    /**Build out the statement years state, the current year through 2018 */
    useEffect(() => {
        let currentYear = new Date().getFullYear()
        let tempYearArray: Array<string> = ["Any"]
        for(let i = currentYear; i >= 2018; i = i - 1){
            tempYearArray.push(String(i))
        }

        statementYearsRef.current = tempYearArray
    }, [])

    /**Apply the filter to the filtered statements array when a filter is selected */
    useEffect(() => {
        if(filterYear === "Any"){
            setFilteredStatements(selectedAccount.accountStatements)
        }
        else {
            let tempFilteredArray = selectedAccount.accountStatements.filter((statement: IStatements) => statement.date.slice(statement.date.length - 4, statement.date.length) === filterYear)
            setFilteredStatements(tempFilteredArray)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterYear])

    /**Make sure the filtered statements array gets populated on render */
    useEffect(() => {
        setFilteredStatements(selectedAccount.accountStatements)
    }, [selectedAccount])

    /**Repopulate the selectedAccount on a page refresh */
    useEffect(() => {
        if(selectedAccount.id === "" && accountInformation[0].id !== ""){
            const adjustedUrl = window.location.pathname.split('/')[2]
            const index = accountInformation.findIndex((account: any) => adjustedUrl === account.id)
            if(index >= 0){
                arrayIndexRef.current = index
                const account = accountInformation[index]
                repopulateAccountDetails(index, account)
            }
            else{
                history.push("/")
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount, accountInformation])    

    /**Repopulates the account details on a page refresh */
    const repopulateAccountDetails = async (index: number, account: any) => {
        const payload = {arrayIndex: index, account: account}
        const response: any = await dispatch(getAccountDetailsAsync(payload))
        try{
            let tempAccount = populateAccountDetails(accountInformation[index], response.payload.response)
            const payload = {arrayIndex: index, account: tempAccount}
            dispatch(setAccountInformationByIndex(payload))            
        }
        catch{
            console.error()
        }
    }

    /**calls to get account statements on page refresh */
    useEffect(() => {
        if(selectedAccount.id !== "" && !selectedAccount.accountStatements.length && gotStatements === "Loading"){
            getAccountStatements()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount])

    /**call to get account statements */
    const getAccountStatements = async () => {
        const apiData = {selectedAccountId: selectedAccount.id, dateFilter: 'All', accountInformation: accountInformation}
        try{
            const response: any = await dispatch(getAccountStatementsAsync(apiData))
            const payload = {arrayIndex: arrayIndexRef.current, statements: populateStatements(response.payload.response.documentIds)}
            dispatch(updateAccountStatementsByIndex(payload))

            // dispatch(addAccountStatement(populateStatements(response.payload.response.documentIds)))
        }
        catch{
            console.log("ERROR RETREIVING THE ACCOUNT STATEMENTS")
        }
    }

    return { windowState, filterYear, setFilterYear, filteredStatements, showPDF, setShowPDF, statementYearsRef, openFilterMenu, setOpenFilterMenu, tempSelectedYear, 
             selectedAccount, setTempSelectedYear, startIndex, setStartIndex, endIndex, setEndIndex, numberOfItemsToShow, setNumberOfItemsToShow }
}