import { downloadAccountNotice } from '../../../api/getAccountInformation'
import MobileDocumentsDisplayHeader from '../../../Pages/Documents/MobileDocumentsPage/components/MobileDocumentsDisplayHeader/MobileDocumentsDisplayHeader'
import { IMobileDocumentAccountAgreementsProps } from '../../DocumentAccountAgreements/MobileDocumentAccountAgreements/interfaces/IMobileDocumentAccountAgreementsProps'
import './MobileDocumentAccountNotices.scss'
import blueDocument from '../../../assets/blueDocument.svg'
// import { convertDateTommddyyyy } from '../../../utils/DateUtils'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import emptyStatements from '../../../assets/emptyStatements.svg'
import warning from '../../../assets/warning.svg'
import { useToastMessage } from '../../../Context/ToastContext/useToastContext'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'
import MobileDocumentCenterFilter from '../../../Pages/Documents/MobileDocumentsPage/components/MobileDocumentCenterFilter/MobileDocumentsFilter'
import { downloadDocuments } from '../../../utils/downloadDocuments'
import { useRef } from 'react'

/**This component render the mobile Document Center page for account notices */
const MobileDocumentAccountNotices = (props: IMobileDocumentAccountAgreementsProps) => {
    const { selectedAccount, selectedPage, setSelectedPage, accountInformation, handleSave, setSelectedDate } = props
    const gotNotices = useAppSelector((state: RootState) => state.selectedAccount.gotNotices)
    const { type, message, setToastMessage, resetToast } = useToastMessage()
    const downloadClickedRef = useRef<boolean>(false)

    /**handle the onClick event and downloads the account statement document */
    const handleStatementDownload = async (id: string) => {
        if(!downloadClickedRef.current){
            downloadClickedRef.current = true
            try {
                const response = await downloadAccountNotice(selectedAccount.id, id, accountInformation)
                downloadDocuments(response.data, selectedAccount.accountNumber, "Others", "")
            }
            catch {
                console.log("FAILED TO DOWNLOAD THE ACCOUNT STATEMENT")
                setToastMessage("ERROR", "Unable to download document. Please try again later")
                resetToast()
            }
            downloadClickedRef.current = false
        }
    }

    return (
        <div className="mobile-documents-account-notices">
            <MobileDocumentsDisplayHeader selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
            {gotNotices !== "Loading" ?
                <div className="mobile-account-notices-wrapper">
                    <div className="mobile-filter-wrapper">
                        <MobileDocumentCenterFilter
                            selectedAccount={selectedAccount}
                            accountInformation={accountInformation}
                            selectedPage={selectedPage}
                            handleSave={handleSave}
                            setSelectedDate={setSelectedDate}
                        />
                    </div>
                    {gotNotices === "Success" ?
                        <>
                            {message !== "" && <ToastMessageDB type={type} message={message} />}
                            {selectedAccount.accountNotices.length > 0 ?
                                <>
                                    {selectedAccount.accountNotices.map((account: any, index: number) => {
                                        return (
                                            <div key={index} className="account-row" onClick={() => handleStatementDownload(account.id)}>
                                                <img src={blueDocument} alt="blue colored document" />
                                                <span className="account-details regular">{account.title} {account.date} - PDF </span>
                                            </div>
                                        )
                                    })}
                                </>
                                :
                                <div className="message-wrapper">
                                    <img className="message-image" src={emptyStatements} alt="no statements" />
                                    <span className="header-text margin h4">No notices</span>
                                    <span className="sub-text regular">Future notices & other documents will be listed here</span>
                                </div>
                            }
                        </>
                        :
                        <div className="message-wrapper">
                            <img className="message-image" src={warning} alt="no statements" />
                            <span className="header-text margin h4">Unable to load data</span>
                            <span className="sub-text regular">Please wait a moment and try again.</span>
                        </div>
                    }
                </div>
                :
                <div className="loading-box" />
            }
        </div>
    )
}

export default MobileDocumentAccountNotices