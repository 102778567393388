import { Control } from "../../Form/Form/Control/Control"
import { VALIDATORS } from "../../Form/Form/Validators/Validators";

/**Controls array for adding beneficiary of 'Person' type */
export const addBeneficiaryControls = () => {
    const firstName = Control(
        'firstName',
        '',
        'First Name',
        'input',
        {
            validations: [VALIDATORS.isRequired()],
            errorMessages: {
                IS_REQUIRED: 'This field is required'
            },
            placeholder: "First Name"
        }
    );

    const middleName = Control('middleName','','Middle Initial (optional)','input',{
        placeholder: "Middle Name"
    });

    const lastName = Control('lastName', '', 'Last Name', 'input',{
        validations:  [VALIDATORS.isRequired()],
        errorMessages: {
            IS_REQUIRED: 'This field is required'
        },
        placeholder: "Last Name"
    });

    const ssn = Control('ssn', '', 'Social Security Number', 'ssn', {
        validations: [VALIDATORS.minLength(11), VALIDATORS.maxLength(11)],
        errorMessages: {
            MIN_LENGTH: `Please enter with format XXX-XX-XXXX`,
            MAX_LENGTH: `Please enter with format XXX-XX-XXXX`
        },
        placeholder: "XXX-XX-XXXX"
    });

    const address1 = Control('address1', '', 'Address 1', 'input',
        {
            validations:  [],
            errorMessages: {
                ONLY_RESIDENTAL: 'Only can add a residental address',
                INVALID_ADDRESS: 'This address could not be validated. Please try again.'
            },
            placeholder: "Street address and number"    
        }
    );

    const address2 = Control('address2', '', 'Address 2 (optional)', 'input',{
        placeholder: "Apartment, suit, unit, building, etc"
    });

    const dateOfBirth = Control('dateOfBirth', '', 'Date of Birth', 'date',{
        validations: [VALIDATORS.isRequired(), VALIDATORS.maxDate(new Date().toLocaleString())],
        errorMessages:{
            IS_REQUIRED: 'This field is required',
            MAX_DATE: "The entered date cannot be a future date.",
        },
        placeholder: "MM/DD/YYYY"
    });

    const city = Control('city', '', 'City', 'input',
        {
            validations:  [],
            placeholder: "City"    
        }
    );

    const state = Control('state', '', 'State', 'select',
        {
            validations:  [],
            placeholder: "Select State",
            selectedOptions: ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", 
                              "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", 
                              "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]
        }
    );

    const zipCode = Control('zipCode', '', 'Zip Code', 'number',
        {
            validations:  [VALIDATORS.zipCodeFormat(5)],
            errorMessages: {
                ZIP_CODE_FORMAT: `Please enter with format XXXXX`
            },
            placeholder: "e.g. 89381"    
        }
    );

    const email = Control('email', '', 'Email Address (optional)', 'email',
        {
            validations: [VALIDATORS.emailFormat()],
            errorMessages: {
                EMAIL_FORMAT: `Please enter with format example@mail.com`,
                INVALID_EMAIL: `Please enter a valid email`
            },
            placeholder: "example@mail.com"
        },
    );

    const phoneNumber = Control('phoneNumber', '', 'Phone Number (optional)', 'phone', 
        {
            validations: [VALIDATORS.minLength(12), VALIDATORS.maxLength(12)],
            errorMessages: {
                MIN_LENGTH: `Please enter with format XXX-XXX-XXXX`,
                MAX_LENGTH: `Please enter with format XXX-XXX-XXXX`
            },
            placeholder: "234-665-2344"
        }
    );

    const relationship = Control('relationship', '', 'Relationship (optional)', 'input', {
        placeholder: "Spouse, Mother, Father, etc"
    });

    const trustName = Control('trustName', '', 'Trust Name', 'input',
        {
            validations: [VALIDATORS.isRequired()],
            errorMessages:{
                IS_REQUIRED: "This field is required"
            },
            placeholder: "Trust Name"
        }
    );

    const charityName = Control('charityName', '', 'Charity Name', 'input',
        {
            validations: [VALIDATORS.isRequired()],
            errorMessages:{
                IS_REQUIRED: "This field is required"
            },
            placeholder: "Charity or Non Profit name"
        }
    );

    const trustEstablishedDate = Control('trustEstablishedDate', '', 'Date Trust Established', 'date',
        {
            validations: [VALIDATORS.maxDate(new Date().toLocaleString()), VALIDATORS.minDate(new Date("01/01/1900").toLocaleString())],
            errorMessages:{
                MAX_DATE: "The entered date cannot be a future date.",
                MIN_DATE: "The minimum date is 01/01/1900."
            },
            placeholder: "MM/DD/YYYY"
        }
    )

    const tin = Control('tin', '', 'Tax Identification Number', 'tin',
        {
            validations: [VALIDATORS.minLength(10), VALIDATORS.maxLength(10)],
            errorMessages: {
                MIN_LENGTH: `Please enter with format XX-XXXXXXX`,
                MAX_LENGTH: `Please enter with format XX-XXXXXXX`
            },
            placeholder: "e.g. 99-9999999"
        }
    )

    return ([
        firstName,
        middleName,
        lastName,
        ssn,
        address1,
        address2,
        dateOfBirth,
        city,
        state,
        zipCode,
        email,
        phoneNumber,
        relationship,
        trustName,
        charityName,
        trustEstablishedDate,
        tin
        ]
    );
}


/**Controls array for adding beneficiary of 'Trust' type*/
// export const addTrustControls = () => {
//     const trustName = Control(
//         'trustName',
//         '',
//         'Trust Name',
//         'input',
//         {
//             validators: [VALIDATORS.isRequired()],
//             errorMessages:{
//                 IS_REQUIRED: "This field is rquired"
//             }
//         }
//     );

//     return ([trustName])
// }


/**Controls array for adding beneficiary of "Charity or Non Profit" type */
// export const addCharityControls = () => {
//     const charityName = Control(
//         'charityName',
//         '',
//         'Charity Name',
//         'input',
//         {
//             validators: [VALIDATORS.isRequired()],
//             errorMessages:{
//                 IS_REQUIRED: "This field is required"
//             }
//         }
//     );

//     return ([charityName])
// }