import axios from "axios"
import { bffBaseUrl } from "../utils/globalVariables"
import { IAccountInformation } from "./getAccountInformation"

/**Handles the post api call to update an accounts terms and conditions */
export const postTermsAndConditions_API = async (accountId: string, docid: string, name: string, accountInformation: Array<IAccountInformation>) => {
    let id = ""

    const index = accountInformation.findIndex((account: IAccountInformation) => account.id === accountId)
    if(index >= 0){
        id = accountInformation[index].id
    }

    return axios.post(`${bffBaseUrl}/accounts/${id}/accountaggrementsign`,
        {
            isSigned: true,
            signedBy: name
        },
        {            
            headers: {
                docid: docid,
            },
        }
    )
}

/**
 * Get the document ID, it is returned on the HEADERS for some reason and a document that doesn't need to be signed or returned
 * comes in the response
 */
export const getDocumentID_API = async (accountId: string) => {
    return axios.get(`${bffBaseUrl}/accounts/${accountId}/terms-and-conditions/content?isSigned=false`,
        {
            responseType: 'blob'
        }
    )
}

/**Get the correct document by ID, when it is alrady signed */
export const downloadSignedAgreement_API = async (accountId: string, docid: string, accountInformation: Array<IAccountInformation>) => {
    let id = ""

    const index = accountInformation.findIndex((account: IAccountInformation) => account.id === accountId)
    if(index >= 0){
        id = accountInformation[index].id
    }

    return axios.get(`${bffBaseUrl}/accounts/${id}/terms-and-conditions/content?isSigned=true`,
        {
            responseType: 'blob',
            headers: {
                docid: docid,
            },
        }
    )
}