/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom";
import { IAccountInformation } from "../../api/getAccountInformation";
import { removeBumpBanner_API } from "../../api/updateBumpRate";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useWindowState } from "../../Context/AccountContext/useWindowState";
import { setAccountTransactionsByIndex, updateBeneficiaryArrayByIndex,  removeBumpRateBannerByIndex, updateAccountStatementsByIndex } from "../../slices/accountInformation/accountInformationSlice";
import { getAccountBeneficiariesAsync, getAccountDetailsAsync, getAccountStatementsAsync, removeBumpRateBanner,  setSelectedAccountTransactions,  updateIsTransactionsLoaded } from "../../slices/selectedAccountSlice/selectedAccountSlice";
import { clearTransactionsArray, getTransactionsAsync, updateOnTransactionDetails } from "../../slices/transactions/transactionsSlice";
import { getEventPath } from "../../utils/getEventPath";
import { useToastMessage } from "../../Context/ToastContext/useToastContext";
import { populateStatements } from "../../utils/populateStatements";

/**
 * Gets the needed information for the AccountDetails page
 */
export const useAccountDetails = () => {
    const history = useHistory()
    const [displayDetails, setDisplayDetails] = useState(false);
    const selectedTransaction = useAppSelector((state: RootState) => state.selectedTransaction.selectedTransaction)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const isAccountInformationLoaded = useAppSelector((state: RootState) => state.accountInformation.isAccountInformationLoaded)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const onTransactionDetails = useAppSelector((state: RootState) => state.transactions.onTransactionDetails)
    const transactions = useAppSelector((state: RootState) => state.transactions.transactions)
    const didTransactionsLoad = useAppSelector((state: RootState) => state.transactions.transactionsLoaded);
    const dispatch = useAppDispatch()
    const [openNavBarMenu, setOpenNavBarMenu] = useState<boolean>(false)
    const { windowState } = useWindowState()
    const [selectedFromAccount, setSelectedFromAccount] = useState<{title: string, type: "Savings" | "", lastFour: string, accountId: string, balance: number}>({
        title: "",
        type: "",
        lastFour: "",
        accountId: "",
        balance: 0
    })
    const [selectedToAccount, setSelectedToAccount] = useState<{title: string, type: "Savings" | "", lastFour: string, accountId: string, balance: number}>({
        title: "",
        type: "",
        lastFour: "",
        accountId: "",
        balance: 0
    })
    const [selectionOptions, setSelectionOptions] = useState<Array<{title: string, type: string, lastFour: string, accountID: string, balance: number}>>([])
    const [transferAmount, setTransferAmount] = useState<number | undefined>()
    const [transferDate, setTransferDate] = useState<string>("")
    const linkedExternalAccounts = useAppSelector((state: RootState) => state.userInformation.externalAccounts)
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)
    const gotUserContactInfo = useAppSelector((state: RootState) => state.userInformation.gotUserContactInfo)
    const { type, message, resetToast, setToastMessage } = useToastMessage()
    const beneficiariesCalledRef = useRef<boolean>(false)
    const statementsCalledRef = useRef<boolean>(false)
    const firstPassRef = useRef<boolean>(true)

    /**reset the toast message when the message has changed */
    useEffect(() => {
        if(message !== "") {
            resetToast()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message])

    /**
     * Used to tell the context that transaction details has been opened
     */
    useEffect(() => {
        if(displayDetails){
            dispatch(updateOnTransactionDetails(true))
        }
        else if(!displayDetails){
            dispatch(updateOnTransactionDetails(false))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[displayDetails])

    /**
     * Uses the URL to get the accountId if no account is selected, then calls setsSelectedAccountById with the accountId
     * which sets the selectedAccount. SetFocus is then set to "Accounts", and setFocusIndex is set to the accountNumber.
     * This is particularly useful when a user manually refreshes on the Account-Details page.
     */
    //  useEffect(() => {
    //      const adjustedUrl = window.location.pathname.split('/')[2]
    //      const index = accountInformation.findIndex((account: any) => adjustedUrl === account.id)
    //      if(selectedAccount.id === "" && accountInformation.length && accountInformation[0].id !== ""){
    //         if(index >= 0){
    //             const account = accountInformation[index]
    //             if(account.id !== ""){
    //                 const payload = {arrayIndex: index, account: account}
    //                 console.log("CALLING TO GET ACCOUNT DETAILS FROM THE USE EFFECT AT ACCOUNT DETAILS.")
    //                 dispatch(getAccountDetailsAsync(payload))
    //             }
    //         }
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [accountInformation])

    //Repopulate any missing data
    useEffect(() => {
        // if(!firstPassRef.current){
            const adjustedUrl = window.location.pathname.split('/')[2]
            const index = accountInformation.findIndex((account: any) => selectedAccount.id === account.id)
            if(selectedAccount.id === adjustedUrl && !selectedAccount.isBeneficiariesLoaded && !beneficiariesCalledRef.current && isAccountDetailsLoaded === "Success"){
                beneficiariesCalledRef.current = true
                getSelectedAccountBeneficiaries(selectedAccount.id, index)
            }
            // if(selectedAccount.id === adjustedUrl && !selectedAccount.gotAccountStatements && !statementsCalledRef.current){
                if(selectedAccount.id === adjustedUrl && !selectedAccount.gotAccountStatements && !statementsCalledRef.current && isAccountDetailsLoaded === "Success"){
                    statementsCalledRef.current = true
                    getAccountStatements(selectedAccount.id, index)
                }
        // }
        // if(firstPassRef.current){
        //     firstPassRef.current = false
        // }
            
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount])

    /**makes an API call to get the account statements */
    const getAccountStatements = async (accountId: string, index: number) => {
        const apiData = {selectedAccountId: accountId, dateFilter: 'All', accountInformation: accountInformation}
        try{
            const response: any = await dispatch(getAccountStatementsAsync(apiData))
            const payload = {arrayIndex: index, statements: populateStatements(response.payload.response.documentIds)}
            dispatch(updateAccountStatementsByIndex(payload))
            // statementsCalledRef.current = false
        }
        catch{
            console.log("ERROR GETTING ACCOUNT STATEMENTS")
        }
    }

    /**Get beneficiaries for the account */
    const getSelectedAccountBeneficiaries = async (accountId: string, index: number) => {
        try{
            const response: any = await dispatch(getAccountBeneficiariesAsync(accountId))
            const payload = {arrayIndex: index, beneficiaryArray: response.payload.beneficiaries}
            dispatch(updateBeneficiaryArrayByIndex(payload))
            // beneficiariesCalledRef.current = false
        }
        catch{
            console.error()
        }
    }
        
    /**Populate the selected accounts transactions with transaction history */
    useEffect(() => {
        if(selectedAccount.isTransactionsLoaded){
            dispatch(setSelectedAccountTransactions(transactions))
            const index = accountInformation.findIndex((account: IAccountInformation) => account.id === selectedAccount.id)
            const payload = {arrayIndex: index, transactions: transactions}
            dispatch(setAccountTransactionsByIndex(payload))
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount.isTransactionsLoaded])


  /**Lock the scroll when the transaction details modal is open, otherwise unlock it */
  useEffect(() => {
    if(selectedTransaction.id !== ""){
        document.body.style.overflow = "hidden";
    }
    else{
        document.body.style.overflow = "auto";
    }

  }, [selectedTransaction])

  /**Cancels the bump rate banner message */
  const handleBannerCancel = async () => {
    try{
        await removeBumpBanner_API(selectedAccount.id)
        dispatch(removeBumpRateBanner(false))
        const index = accountInformation.findIndex((account: IAccountInformation) => account.id === selectedAccount.id)
        if(index >= 0){
            const payload = {arrayIndex: index, show: false}
            dispatch(removeBumpRateBannerByIndex(payload))
        }
        setToastMessage("SUCCESS", "Banner removed.")
    }
    catch{
        setToastMessage("ERROR", "Unable to save changes. Please try again later.")
        resetToast()
        console.log("REMOVE BANNER SERVICE FAILED")
    }
  }

      /**Closes the pop up menu when a click is detected outside of the menu, only used with mobile flow */
      useEffect(() => {
        if(windowState === "Mobile"){
            const handleClickOutside = (event: any) => {
                const path = getEventPath(event)
                if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("menu-wrap")) === -1){
                    document.body.style.overflow = "auto"
                    setOpenNavBarMenu(false)
                }
            }
    
            document.addEventListener("click", handleClickOutside, true)
            return () => {document.removeEventListener('click', handleClickOutside, true); document.body.style.overflow = "auto"}
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**If we are in mobile flow lock the scroll bar on render */
    useEffect(() => {
        if(windowState === "Mobile" && openNavBarMenu){
            document.body.style.overflow = "hidden"
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openNavBarMenu])

    /**Once accountInformation is rendered, build the accounts for the selection drop downs */
    useEffect(() => {
        if(accountInformation.length && accountInformation[0].id !== ""){
            buildSelectionOptions()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInformation])

    /**Builds the accounts for the select dropdowns */
    const buildSelectionOptions = () => {
        let tempAccounts: Array<{title: string, type: string, lastFour: string, accountID: string, balance: number}> = []
        let addAccount = {title: "CREDIT ONE ACCOUNTS", type: "" as "", lastFour: "", accountID: "", balance: 0}
        tempAccounts.push(addAccount)
        for(let i = 0; i < accountInformation.length; i = i + 1){
            if(accountInformation[i].accountType === "Savings"){
                let addAccount = {
                    title: accountInformation[i].accountTitle, 
                    type: "Savings" as "Savings", 
                    lastFour: accountInformation[i].accountNumber, 
                    accountID: accountInformation[i].id, 
                    balance: accountInformation[i].balance,
                    accountType: 'Internal'
                }
                tempAccounts.push(addAccount)
            }
        }
        addAccount = {title: "EXTERNAL ACCOUNTS", type: "" as "", lastFour: "", accountID: "", balance: 0}
        tempAccounts.push(addAccount)

        for(let i = 0; i < linkedExternalAccounts.length; i = i + 1){
            if(['Active', 'Verified', 'Linked'].includes(linkedExternalAccounts[i].status)){
                let addAccount = {
                    title: linkedExternalAccounts[i].accountNickName, 
                    type: linkedExternalAccounts[i].type, 
                    lastFour: linkedExternalAccounts[i].lastFour as string,
                    accountID: linkedExternalAccounts[i].id,
                    accountType: 'External',
                    balance: 0
                 }
                tempAccounts.push(addAccount)
            }
        }
        if(tempAccounts.length){
            setSelectionOptions(tempAccounts)
        }
    }    

    return { selectedAccount, onTransactionDetails, displayDetails, setDisplayDetails, selectedTransaction, 
             history, isAccountInformationLoaded, transactions, handleBannerCancel, dispatch, openNavBarMenu, setOpenNavBarMenu,
             selectedFromAccount, setSelectedFromAccount, selectedToAccount, setSelectedToAccount, selectionOptions, setSelectionOptions,
             transferAmount, setTransferAmount, transferDate, setTransferDate, isAccountDetailsLoaded, type, message, didTransactionsLoad, gotUserContactInfo }
}
