import axios from "axios"
import { bffBaseUrl } from "../utils/globalVariables"
import { IAccountInformation } from "./getAccountInformation"

/**
 * Holds the account that the interest payments should be paid to
 */
export interface IInterestSettings {
    /*The account number that the interest payments should be applied to*/
    accountNumber: string
}

/**
 * Updates the interest settings, or the account that the interest should be paid to
 */
export const updateInterestSettings_API = (accountId: string, account: any, accountInformation: Array<IAccountInformation>) => {
    let id = ""

    const index = accountInformation.findIndex((account: IAccountInformation) => account.id === accountId)
    if(index >= 0){
        id = accountInformation[index].id
    }
    return axios.put(`${bffBaseUrl}/accounts/${id}/interestpayments`,
        account
    )
}