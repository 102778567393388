import { useEffect, useRef, useState } from "react"
import { IAddress } from "../../../api/User/interfaces"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { addUserAddress, removeAddressByIndex, setCheckInternalToken, setOTPPassed, updateUserAddress } from "../../../slices/userInformation/userInformationSlice"
import { getEventPath } from "../../../utils/getEventPath"
import { deleteAddress_API, patchAddress_API, postAddress_API } from "../../../api/User/address"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { useUserProfileContext } from "../../../Pages/UserProfile/Context/useUserProfileContext"

/**A custom use hook for Address modification found in the user profile flow */
export const useModifyAddresses = (index: number, addressAction: "EditMain" | "Edit" | "Add" | "Remove" | "", setOpenEditPage: any) => {
    const { windowState } = useWindowState()
    const userInformation = useAppSelector((state: RootState) => state.userInformation)
    const { form, dispatchForm, formFilled, setFormFilled } = useUserProfileContext()
    // const [form, dispatchForm] = useForm('addAddressForm', addAddressControls())
    const [showCheckmark, setShowCheckmark] = useState<boolean>((addressAction !== "Add" && userInformation.contactInformation.addresses[index]?.isMailing) ? true : false)
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    // const [formFilled, setFormFilled] = useState<boolean>(false)
    const [openMailingModal, setOpenMailingModal] = useState<boolean>(false)
    const [purpose, setPurpose] = useState<"Remove" | "Edit" | "Delete" | "Add" | "">("") 
    const addressRef= useRef<IAddress>(userInformation.contactInformation.addresses[index])
    const dispatch = useAppDispatch()
    const firstCheckRef = useRef<boolean>(true)
    const foundChangeRef = useRef<boolean>(false)
    const handleSaveClickedRef = useRef<boolean>(false)
    const removeAddressClickedRef = useRef<boolean>(false)
    const OTPPassed = useAppSelector((state: RootState) => state.userInformation.OTPPassed)
    const { setToastMessage, type, message } = useToastMessage()
    const apiStatusRef = useRef<true | false | null>(null)
    const ZIPCODE_FORMAT = new RegExp("^[0-9]{5}(-[0-9]{4})?$")

   /**
   * When page is rendered, there's a check to see if the form is already filled
   */
   useEffect(() => {
    if(!firstCheckRef.current){
       setFormFilled(Object.keys(form.controls).every(e => {
        if(form.controls[e].isRequired) {
            return !!form.controls[e].value
        }
        if(form.controls.zipCode.value.length > 0 && !ZIPCODE_FORMAT.test(form.controls.zipCode.value)){
            return false
        }

        return true;
       }))
    }
    else{
        firstCheckRef.current = false
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [form.controls, showCheckmark]);
 
    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if(windowState === "Mobile"){
                const path = getEventPath(event)
                if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("menu-wrap")) === -1){
                    document.body.style.overflow = "auto";
                    setShowTooltip(false)
                }
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Lock the scroll bar when the tooltip modal is open, otherwise unlock the scroll bar */
    useEffect(() => {
        if(showTooltip){
            document.body.style.overflow = "hidden"
        }
        else{
            document.body.style.overflow = "auto"
        }
    }, [showTooltip])
    
    /**Populate the form with the current values */
    // useEffect(() => {
        // if(addressAction !== "Add"){
            // const addressData = {
            //     id: userInformation.contactInformation.addresses[index].id,
            //     addressLine1: userInformation.contactInformation.addresses[index].addressLine1,
            //     addressLine2: userInformation.contactInformation.addresses[index].addressLine2,
            //     city: userInformation.contactInformation.addresses[index].city,
            //     state: userInformation.contactInformation.addresses[index].state,
            //     zipCode: userInformation.contactInformation.addresses[index].zipCode,
            // }
            // form.overrideControlValues(addressData)
            // form.enableAllInputs()
            // dispatchForm()        
        // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])
    
    /**open the change mailing address modal if the mailing address has changed */
    useEffect(() => {
        if(addressAction !== "Add" && showCheckmark !== userInformation.contactInformation.addresses[index].isMailing){
            if(!showCheckmark && userInformation.contactInformation.addresses[index].isMailing){
                setPurpose("Remove")
            }
            else if(showCheckmark && !userInformation.contactInformation.addresses[index].isMailing){
                setPurpose("Edit")
            }
        }
        else{
            setPurpose("")
        }
        if(showCheckmark && addressAction === "Add"){
            setPurpose("Add")
        }
        else if(!showCheckmark && addressAction === "Add"){
            setPurpose("")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showCheckmark])

    /**Handles removing an address */
    const handleRemoveAddress = () => {
        removeAddressClickedRef.current = true
        if(userInformation.contactInformation.addresses[index].isMailing){
            deleteAddressServiceCalls()
        }
        else{
            dispatch(setOTPPassed(false))
            dispatch(setCheckInternalToken(true))
        }
    }

    /**handle delete address service calls */
    const deleteAddressServiceCalls = async () => {
        removeAddressClickedRef.current = false
        if(showCheckmark){
            setPurpose("Delete")
            setOpenMailingModal(true)
        }
        else{
            if(showCheckmark !== userInformation.contactInformation.addresses[index].isMailing){
                setPurpose("Delete")
                setOpenMailingModal(true)
            }
            else{
                try{
                    await deleteAddress_API(userInformation.contactInformation.addresses[index].id)
                    dispatch(removeAddressByIndex(index))
                    setToastMessage("SUCCESS", "Address removed")
                    setOpenEditPage("")
                    setFormFilled(false)
                    form.resetForm()
                    dispatchForm()
                }
                catch{
                    handleAPIError()
                }
            }
        }
    }

    /**useEffect monitors the OTP, if successful the service calls function will be called */
    useEffect(() => {
        if(OTPPassed && handleSaveClickedRef.current){
            handleServiceCalls()
        }
        else if(OTPPassed && removeAddressClickedRef.current){
            deleteAddressServiceCalls()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OTPPassed])

    /**handles the multiple address service calls */
    const handleServiceCalls = async () => {
        handleSaveClickedRef.current = false
        apiStatusRef.current = true
        let selectedAddress = {
            id: addressAction === "Add" ? crypto.getRandomValues(new Uint32Array(5)).toString() : userInformation.contactInformation.addresses[index].id,
            addressLine1: form.controls.addressLine1.value.trim(),
            addressLine2: form.controls.addressLine2.value.trim() || "",
            city: form.controls.city.value.trim(),
            state: form.controls.state.value,
            zipCode: form.controls.zipCode.value,
            isMailing: showCheckmark ? true : false,
            isResidential: true,
            addressType: addressAction === "EditMain" ? "Residential" : "Alternate",
            country: "US"
        }

        if(addressAction !== "Add"){
            for(const [controlKey, controlValue] of Object.entries(form.controls)){
                //this scenario is when the edit action is adding the mailing flag to an address that did not initially have the mailing flag
                if(addressRef.current[(controlKey as keyof IAddress)] !== controlValue.value && showCheckmark) {
                    setOpenMailingModal(true)
                    setPurpose("Edit")
                    foundChangeRef.current = true
                    break;
                }
            }
        }

        //this scenario occurs when an addresses is being edited with the mailing flag initially selected
        if(purpose !== ""){
            setOpenMailingModal(true)
        }

        //this scenario is to add or edit addresses when the mailing flag was not initially set, nor selected during the edit/add process
        if(!openMailingModal && !foundChangeRef.current && purpose === ""){
            if(addressAction === "Add"){
                try{
                    const response = await postAddress_API(selectedAddress)
                    selectedAddress.id = response.data.id
                    dispatch(addUserAddress(selectedAddress))
                    setToastMessage("SUCCESS", "Address added")
                    setFormFilled(false)
                    setOpenEditPage("")
                    form.resetForm()
                    dispatchForm()
                }
                catch{
                    handleAPIError()
                }
            }
            else{
                try{
                    await patchAddress_API(selectedAddress)
                    const payload = {arrayIndex: index, address: selectedAddress}
                    dispatch(updateUserAddress(payload))
                    setToastMessage("SUCCESS", "Address changed")
                    setFormFilled(false)
                    setOpenEditPage("")
                    form.resetForm()
                    dispatchForm()
                }
                catch{
                    handleAPIError()
                }
            }
        }
        apiStatusRef.current = false
    }

    /**handles the API error states */
    const handleAPIError = () => {
        setToastMessage("ERROR", "Unable to save changes. Please try again later")
        // resetToast()
        dispatch(setOTPPassed(false))
    }

    /**Handles the onClick for the Save button */
    const handleOnSave = async () => {
        dispatch(setOTPPassed(false))
        if(addressAction !== "Add" && (showCheckmark || userInformation.contactInformation.addresses[index].isMailing)){
            handleServiceCalls()
        }
        else{
            handleSaveClickedRef.current = true
            dispatch(setCheckInternalToken(true))
        }
        return new Promise((resolve) => {
            let interval: any
            interval = setInterval(() => {
                if(apiStatusRef.current === false){
                    apiStatusRef.current = null
                    resolve(true)
                    clearInterval(interval)
                }
            }, 100)
        })
    }

    return { userInformation, form, dispatchForm, showCheckmark, setShowCheckmark, showTooltip, setShowTooltip, formFilled, handleOnSave, openMailingModal, 
             setOpenMailingModal, purpose, handleRemoveAddress, type, message, setFormFilled }
}