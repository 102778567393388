/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react"
import { IAccountInformation } from "../../../api/getAccountInformation"
import { IBeneficiary, patchBeneficiaryDetails_API } from "../../../api/getBeneficiaries"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { IFormContext, TControlDispatch } from "../../../Form/useForm/useForm"
import { updateBeneficiaryBySelectedAccountBeneficiaryIndex } from "../../../slices/accountInformation/accountInformationSlice"
import { setUpdatedBeneficiaryByIndex } from "../../../slices/selectedAccountSlice/selectedAccountSlice"
import { checkControlValues } from "../../../utils/IsFormFilled"
import { useScrollToTop } from "../../ScrollToTopHook/useScrollToTop"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"

/**
 * Custom hook for the DesktopEditPerson component. It allows the user to update the input fields
 * and updates the beneficairy array with the new data.
 */
export const useEditBeneficiaryPerson = (setEditProfile: any, setEditBeneficiary: any, selectedBeneficiary: IBeneficiary, form: IFormContext) => {
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const dispatch = useAppDispatch()
    useScrollToTop()
    const [formFilled, setFormFilled] = useState(false)
    const { setType, setMessage, type, message, setToastMessage, resetToast } = useToastMessage()
    const submitClickedRef = useRef<boolean>(false)

    /**
    * When page is rendered, there's a check to see if the form is already filled
    */
    useEffect(() => {
        setFormFilled(checkControlValues(form.controls, "Person"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.controls]);

    /**
    * Sets the new beneficiary to what the user enetered in the form, then sets the beneficiary array, the selectedAccount beneficiaries,
    * and the particular beneficiaries from the accountInformation array
    */
    const handleOnSubmit = async () => {
        if(!submitClickedRef.current){
            submitClickedRef.current = true
            let values = form.controls
            let updatedBeneficiary: IBeneficiary = {
                id: selectedBeneficiary.id,
                name: values.firstName.value + " " + values.lastName.value,
                firstName: values.firstName.value,
                middleName: values.middleName.value,
                lastName: values.lastName.value,
                dateOfBirth: values.dateOfBirth.value,
                tin: values.ssn.value,
                ssn: values.ssn.value,
                relationship: values.relationship.value,
                address1: values.address1.value.trim(),
                address2: values.address2.value.trim(),
                city: values.city.value.trim(),
                state: values.state.value,
                zipCode: values.zipCode.value,
                phoneNumber: values.phoneNumber.value,
                email: values.email.value,
                type: "Person",
                percentage: selectedBeneficiary.percentage,
                isDeleted: selectedBeneficiary.isDeleted
            }
    
            try{
                await patchBeneficiaryDetails_API(selectedAccount.id, updatedBeneficiary, accountInformation)
                let index = selectedAccount.beneficiaries.findIndex((beneficiary: IBeneficiary) => beneficiary.id === updatedBeneficiary.id)
                const payload = {arrayIndex: index, beneficiary: {...updatedBeneficiary, ssn: values.ssn.value}}
                dispatch(setUpdatedBeneficiaryByIndex(payload))
                let accountIndex = accountInformation.findIndex((account: IAccountInformation) => account.accountNumber === selectedAccount.accountNumber)
                index = accountInformation[accountIndex].beneficiaries.findIndex((beneficiary: IBeneficiary) => beneficiary.id === updatedBeneficiary.id)
                const accountsPayload = { arrayIndex: accountIndex, beneficiaryIndex: index, beneficiary: updatedBeneficiary }
                dispatch(updateBeneficiaryBySelectedAccountBeneficiaryIndex(accountsPayload))
                setEditProfile(false)
                setEditBeneficiary(false)
                setType("SUCCESS")
                setMessage("Beneficiary profile updated")
                submitClickedRef.current = false
            }
            catch{
                console.log("FAILED TO UPDATE THE BENEFICIARY DETAILS")
                setToastMessage('ERROR', "Unable to save changes. Please try again later.")
                resetToast()
                //setType("ERROR")
                // setMessage("Unable to save changes. Please try again later.")
                // setTimeout(() => {
                //     setMessage("")
                // }, 4000)
                submitClickedRef.current = false
            }
            return Promise.resolve(true)
        }
    }

    return { handleOnSubmit, setFormFilled, formFilled, type, message }
}
