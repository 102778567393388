import axios from "axios"
import { bffBaseUrl } from "../utils/globalVariables"
import { IAccountInformation } from "./getAccountInformation"

/**
 * Maturity Settings interface
 */
 export interface IMaturitySetting {
    /*The Selected Option, Renew = same term, Change = new term, close = transfer funds out to another account */
    option: "Renew" | "Change" | "Close" | ""

    /*The length of the selected term */
    termLength: string

    /*The ID of the account that the funds should be transfered to on closing */
    accountId?: string

    /**The next selected CD product, this is used when the 'Change' option has been selected */
    changeProduct?: string

    /**The linked account that the funds should be transfered to, this is used when 'Closing' a CD */
    transferAccount?: string

    /**The amount to transfer */
    transferAmount?: number

    /**The type of transfer */
    transferType?: "Add" | "Withdraw" | ""

    /**The transfer to account id */
    transferId?: string

    /**The Id to the account that funds should be transfered to on the close action */
    closedAccountId?: string
    
    /**The name of the account that funds should be transfered to on the close action */
    closedAccountName?: string

    /**The product code for the renew product */
    changeProductCode?: string

    /**The type of the account to transfer with ie: "external" || "internal" */
    transferAccountType?: "external" | "internal" | ""

    /**The scheduled transfer ID */
    scheduledTransferId?: string
 }

/**
 * Update the maturity settings on an account.
 * @param selectedAccount IAccountInformation object with a key for maturity settings and accountNumber
 */
// export const updateMaturitySettings_API = (selectedAccount: IAccountInformation) => {
//     return Promise.resolve(true)
// }

/**Update a selected accounts maturity settings */
export const updateMaturitySettings_API = (accountId: string, action: "renew" | "close" | "transfer",  product: string, linkedAccount: string, amount: number, transferOption: "add" | "withdraw" | "", accountType: "external" | "internal" | "", accountInformation: Array<IAccountInformation>) => {
  let id = ""

  const index = accountInformation.findIndex((account: IAccountInformation) => account.id === accountId)
  if(index >= 0){
    id = accountInformation[index].id
  }

  if(action === "renew"){
    return axios.put(`${bffBaseUrl}/accounts/${id}/maturity`,
      {
        action: action,
        product: product
      }
    )
  }
  else if(action === "close"){
    return axios.put(`${bffBaseUrl}/accounts/${id}/maturity`,
      {
        action: action,
        linkedAccount: linkedAccount,
        accountType: accountType
      }
    )
  }
  return axios.put(`${bffBaseUrl}/accounts/${id}/maturity`,
    {
      action: action,
      linkedAccount: linkedAccount,
      amount: amount,
      transferOption: transferOption,
      accountType: accountType
    }
    // {
    //     headers: {
    //         Authorization: `Bearer ` + token
    //     }
    // }
  )
}