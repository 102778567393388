import { useRef, useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { useWindowState } from "../../Context/AccountContext/useWindowState"
import { getAccountAgreementsAsync, getAccountNoticesAsync, getAccountStatementsAsync, setGotAccountAgreements, setGotAccountNotices, setGotAccountStatements, setSelectedAccount } from "../../slices/selectedAccountSlice/selectedAccountSlice"
import { IStatements } from "../../api/getStatements"
import { IAccountInformation } from "../../api/getAccountInformation"
import { getTaxDocumentsAsync, updateAccountAgreementsByIndex, updateAccountNoticesByIndex, updateAccountStatementsByIndex } from "../../slices/accountInformation/accountInformationSlice"
import { populateAccountAgreements } from "../../utils/populateStatements"

/**
 * The custom hook holds all logic for the documents page wrapper component
 */
export const useDocumentsPage = () => {
    const { windowState } = useWindowState()
    const [ selectedPage, setSelectedPage ] = useState<string>("") 
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const dispatch = useAppDispatch()
    const availablePages = useRef<Array<string>>(["Statements", "Tax documents", "Agreements", "Notices & other documents"])
    const dateSelections = useRef<Array<string>>([""])
    const [ selectedDate, setSelectedDate ] = useState<string>("All")
    const [filteredAccounts, setFilteredAccounts] = useState<Array<IStatements>>(selectedAccount.accountStatements)
    const arrayIndexRef = useRef<number>(0)
    const gotDocumentsRef = useRef<boolean>(false)
    const gotStatementsRef = useRef<boolean>(false)

    /**initialize the selectedAccount to the first index of accountInformation */
    useEffect(() => {
        if(selectedAccount.id === "" && accountInformation[0].id !== ""){
            dispatch(setSelectedAccount(accountInformation[0]))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInformation])

    //the useEffect gets the location we need to go to on render
    useEffect(() => {
        const adjustedUrl = window.location.pathname.split('/')[2];
        switch(adjustedUrl){
            case "statements" :
            {
                setSelectedPage("Statements")
                break;
            }
            case "tax":
            {
                setSelectedPage("Tax documents")
                break;
            }
            case "agreements":
            {
                setSelectedPage("Agreements")
                break;
            }
            case "notices":
            {
                setSelectedPage("Notices & other documents")
                break;
            }
            default: 
            {   
                if(windowState !== "Mobile"){
                    setSelectedPage("Statements")
                }
                else{
                    setSelectedPage("")
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Builds the Date Period array of selectable dates, beginning with the current year and ending with 2018 */
    useEffect(() => {
        const currentYear = new Date().getFullYear()
        let tempYearArray: Array<string> = ["All"]
        for(let i = currentYear; i > 2017; i = i - 1){
            tempYearArray.push(String(i))
        }
        dateSelections.current = tempYearArray
    }, [])

    /**handles the onClick event for saving the filter selection on the mobile filter, the id is of the account selected */
    const handleSave = (id: string) => {
        const arrayIndex = accountInformation.findIndex((account: IAccountInformation) => id === account.id)
        switch(selectedPage) {
            case "Statements": {
                dispatch(setSelectedAccount(accountInformation[arrayIndex]))
                if(selectedDate === "All"){
                    setFilteredAccounts(accountInformation[arrayIndex].accountStatements)
                }
                else{
                    let tempDocuments = accountInformation[arrayIndex].accountStatements.filter((statement: IStatements) => selectedDate === statement.date.slice(statement.date.length - 4, statement.date.length))
                    setFilteredAccounts(tempDocuments)
                }
                break;
            }
            case "Agreements": {
                // const arrayIndex = accountInformation.findIndex((account: IAccountInformation) => id === account.id)
                dispatch(setSelectedAccount(accountInformation[arrayIndex]))
                break;
            }
            case "Notices & other documents": {
                dispatch(setSelectedAccount(accountInformation[arrayIndex]))
            }
        }
    }

    /**when the selected date changes, filter out the results by date, used for desktop and hybrid */
    useEffect(() => {
        if(selectedAccount.accountStatements.length > 0 && windowState !== "Mobile"){
            if(selectedDate === "All"){
                setFilteredAccounts(selectedAccount.accountStatements)
            }
            else{
                let filteredStatements = selectedAccount.accountStatements.filter((statement: IStatements) => 
                    statement.date.slice(statement.date.length - 4, statement.date.length) === selectedDate
                )
                setFilteredAccounts(filteredStatements)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])

    /**
     * when the selectedAccount changes find the index within the accountInformation array and set it to the arrayIndex ref
     * call to get account statements if no statements exist.
     */
    useEffect(() => {
        if(selectedAccount.id !== ""){
            const arrayIndex = accountInformation.findIndex((account: IAccountInformation) => account.id === selectedAccount.id)
            if(arrayIndex >= 0){
                arrayIndexRef.current = arrayIndex
                // setFilteredAccounts(accountInformation[arrayIndex].accountStatements)
            } 
            if(selectedAccount.accountStatements && !selectedAccount.gotAccountStatements && selectedPage === "Statements"){
                getSelectedAccountStatements(selectedAccount.id, "All", arrayIndex)
            }
            else{
                setFilteredAccounts(selectedAccount.accountStatements)
                dispatch(setGotAccountStatements("Success"))
            }
            if(selectedAccount.accountNotices && !selectedAccount.gotAccountNotices && selectedPage === "Notices & other documents"){
                getAccountNotices(selectedAccount.id, arrayIndex)
            }
            else{
                dispatch(setGotAccountNotices("Success"))
            }
            if(selectedAccount.accountAgreements && !selectedAccount.gotAccountAgreements && selectedPage === "Agreements"){
                getAccountAgreements(selectedAccount.id, arrayIndex)
            }
            else{
                dispatch(setGotAccountAgreements("Success"))
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount, selectedPage])

    /**calls to get the account statements */
    const getSelectedAccountStatements = async (selectedAccountId: string, dateFilter: string, arrayIndex: number) => {
        const apiPayload = {selectedAccountId: selectedAccountId, dateFilter: dateFilter, accountInformation: accountInformation}
        try{
            const response: any = await dispatch(getAccountStatementsAsync(apiPayload))
            setFilteredAccounts(response.payload.response.documentIds)
            const payload = {arrayIndex: arrayIndex, statements: response.payload.response.documentIds}
            dispatch(updateAccountStatementsByIndex(payload))
            gotStatementsRef.current = true
            // if(!gotDocumentsRef.current){
            //     gotDocumentsRef.current = true
            //     setTimeout(() => {
            //         getTaxDocuments()
            //     }, 2000)
            // }
        }
        catch{
            console.log("ERROR GETTING THE ACCOUNT STATEMENTS")
        }
    }

    /**on render get the tax documents and account notices*/
    useEffect(() => {
        if(accountInformation[0].id !== "" && !gotDocumentsRef.current){
            gotDocumentsRef.current = true
            getTaxDocuments()
            // getAccountNotices()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInformation, selectedAccount])
        
    /**calls to get the users tax documents */
    const getTaxDocuments = async () => {
        try{
            await dispatch(getTaxDocumentsAsync())
        }
        catch{
            console.log("ERROR GETTING THE USERS TAX DOCUMENTS")
        }
    }

    /**calls to get the users account notices & other documents */
    const getAccountNotices = async (selectedAccountId: string, arrayIndex: number) => {
        try{
            const response = await dispatch(getAccountNoticesAsync(selectedAccountId))
            const payload = {arrayIndex: arrayIndex, notices: response.payload.documentIds}
            dispatch(updateAccountNoticesByIndex(payload))
        }
        catch{
            console.log("ERROR GETTING THE USERS ACCOUNT NOTICES")
        }
    }

    /**calls to get the users account agreements */
    const getAccountAgreements = async (selectedAccountId: string, arrayIndex: number) => {
        try{
            const response = await dispatch(getAccountAgreementsAsync(selectedAccountId))
            // console.log("THE RESPONSE IN GET ACCOUNT AGREEMENTS IS :", response)
            const payload = {arrayIndex: arrayIndex, agreements: populateAccountAgreements(response.payload.tncDocNames)}
            dispatch(updateAccountAgreementsByIndex(payload))
        }
        catch{
            console.log("ERROR GETTING THE USERS ACCOUNT AGREEMENTS")
        }
    }

    return { windowState, selectedPage, setSelectedPage, availablePages, dateSelections, selectedDate, setSelectedDate,
             selectedAccount, handleSave, filteredAccounts }
}