import { IMobileStatementsLandingProps } from './interfaces/IMobileStatementsLandingProps'
import './MobileStatementsLanding.scss'
import mobileFilter from '../../../assets/mobileFilter.svg'
import { IStatements } from '../../../api/getStatements'
import MobileFilter from './components/MobileFilter/MobileFilter'
import emptyStatements from '../../../assets/emptyStatements.svg'
import { PaginationComponent } from '../../PaginationComponent/PaginationComponent'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import warning from '../../../assets/warning.svg'
import { useToastMessage } from '../../../Context/ToastContext/useToastContext'
import { downloadAccountStatement } from '../../../api/getAccountInformation'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'
import { useRef } from 'react'
import { downloadDocuments } from '../../../utils/downloadDocuments'

/**
 * The component renders the landing page for the mobile version of statements.  That is, it shows all the 
 * statements associated with a particular acccount, as well as a filter to sort them.
 */
const MobileStatementsLanding = (props: IMobileStatementsLandingProps) => {
    const { filterYear, setFilterYear, filteredStatements, setShowPDF, statementYearsRef, openFilterMenu, setOpenFilterMenu,
            tempSelectedYear, setTempSelectedYear, selectedAccount, startIndex, setStartIndex, endIndex, setEndIndex, numberOfItemsToShow, 
            setNumberOfItemsToShow } = props
    const gotStatements = useAppSelector((state: RootState) => state.selectedAccount.gotStatements)
    const { message, type, setToastMessage, resetToast } = useToastMessage()
    const downloadClickedRef = useRef<boolean>(false)
    const accountInformation = useAppSelector((state:RootState) => state.accountInformation.accountInformation)

    /**handle the onClick event and downloads the account statement document */
    const handleStatementDownload = async (id: string) => {
        if(!downloadClickedRef.current){
            downloadClickedRef.current = true
            try{
                const response = await downloadAccountStatement(selectedAccount.id, id, accountInformation)
                downloadDocuments(response.data, selectedAccount.accountNumber, "Statement", "")
            }
            catch{
                console.log("FAILED TO DOWNLOAD THE ACCOUNT STATEMENT")
                setToastMessage("ERROR", "Please try again later")
                resetToast()
            }
            downloadClickedRef.current = false
        }
    }

    return(
        <div className="mobile-statements-landing-wrap">
            {gotStatements !== "Loading" ?
                <div className="encapsulation-card">
                    {gotStatements === "Success" ?
                        <>
                        <div className="title-wrap">
                            <span className="statements-text h3">Statements</span>
                            <span className="sub-text small">View or download your account statements</span>
                        </div>
                        <div className="filter-wrapper" onClick={() => setOpenFilterMenu(!openFilterMenu)}>
                            <img src={mobileFilter} alt="year filter menu" />
                            <span className="filter-text regular">Filter</span>
                        </div>
                            <div className="card-wrapper">
                                {filteredStatements.length > 0 ?
                                <>
                                {(filteredStatements && filteredStatements.length > 0) && filteredStatements.slice(startIndex, endIndex).map((statement: IStatements, index: number) => {
                                    return(
                                        <div key={index}>
                                            <div className="row-wrapper" onClick={() => handleStatementDownload(statement.id)} >
                                                <span className="statement-text small" onClick={() => setShowPDF(true)} >Statement - {statement.date} (PDF)</span>
                                            </div>
                                            <div className="border-line" />
                                            {/* {filteredStatements.length - 1 !== index && <div className="border-line" />} */}
                                        </div>
                                    )
                                })}
                                </>
                                :
                                    <div className="message-statements-wrapper">
                                        <img className="message-image" src={emptyStatements} alt="empty statements" />
                                        <span className="text mobileh4">No Statements to display</span>
                                        <span className="text regular">Statements will appear here</span>
                                    </div>
                                }
                            </div>
                        {(filteredStatements && filteredStatements.length) > 10 &&
                            <div className="pagination-wrapper">
                                <PaginationComponent 
                                    type="Mobile"
                                    itemType=""
                                    totalItems={filteredStatements.length}
                                    setStartIndex={setStartIndex}
                                    setEndIndex={setEndIndex}
                                    numberOfItemsToShow={numberOfItemsToShow}
                                    setNumberOfItemsToShow={setNumberOfItemsToShow}
                                    spaceBetween={true}
                                />
                            </div>
                        }
                        {openFilterMenu && 
                        <>
                            <div className="overlay" />
                            <MobileFilter 
                                filterYear={filterYear}
                                setFilterYear={setFilterYear}
                                statementYearsRef={statementYearsRef}
                                setOpenFilterMenu={setOpenFilterMenu} 
                                tempSelectedYear={tempSelectedYear}
                                setTempSelectedYear={setTempSelectedYear}       
                            />
                        </>
                        }
                        {message !== "" && <ToastMessageDB type={type} message={message} /> }
                        </>
                        :
                        <div className="message-statements-wrapper">
                            <img className="message-image" src={warning} alt="empty statements" />
                            <span className="text mobileh4">Unable to load data</span>
                            <span className="text regular">Please wait a moment and try again</span>
                        </div>
                    }
                </div>
                :
                <div className="loading-box" />
            }
        </div>
    )
}

export default MobileStatementsLanding