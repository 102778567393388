import { useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { IAccountInformation, IProduct } from "../../../api/getAccountInformation"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { updateGraceTermChangeByIndex, updatesGraceSettingsTransferFundsByIndex, closeAccountByIndex } from "../../../slices/accountInformation/accountInformationSlice"
import { updateGraceTermChange, closeSelectedAccount, updateGraceSettingsOnTransferFunds, updateGraceFundTransferId } from "../../../slices/selectedAccountSlice/selectedAccountSlice"
import { calculateMaturityDate } from "../../../utils/calculateMaturityDate"
import { convertDateTommddyyyy } from "../../../utils/DateUtils"
import { changeGracePeriodTerm_API, closeGraceAccount_API, createGraceFundTransfer_API } from "../../../api/gracePeriodPostCalls"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { useNotifications } from "../../../Context/NotificationContext/useNotificationContext"

/**A custom use hook for the grace period confirm CD change page */
export const useGraceConfirmTermChange = (
        fundTransfer: boolean, 
        setFundTransfer: any, 
        closeAccount: boolean, 
        setCloseAccount: any, 
        transferAccount: {id: string, title: string, lastFour: string, accountType: "external" | "internal" | ""},
        setChosenTerm: React.Dispatch<React.SetStateAction<IProduct>>,
        transferType: "Add" | "Withdraw" | ""
    ) => {
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const dispatch = useAppDispatch()
    const history = useHistory()
    const { setToastMessage, resetToast } = useToastMessage()
    const handleConfirmClickedRef = useRef<boolean>(false)
    const { notifications, handleDismiss } = useNotifications()
    
    /**
     * handles the onclick event for the Confirm Update button 
     * the function will update the accountType, description, term, interestRate, maturityDate, and maturity default settings
     */
    const handleConfirmUpdate = async (chosenTerm: IProduct, setShowTermChange: any) => {
        // console.log("THE CHOSEN TERM IS :", chosenTerm)
        if(!handleConfirmClickedRef.current){
            handleConfirmClickedRef.current = true
            if(!fundTransfer && !closeAccount){
                try{
                    const response = await changeGracePeriodTerm_API(selectedAccount.id, chosenTerm.code, chosenTerm.term, accountInformation)
                    // const payload = {cdGroup: chosenTerm.group, cdTerm: chosenTerm.term, apy: chosenTerm.apy, productCode: chosenTerm.code}
                    // await dispatch(updateGraceTermChange(payload))
                    // const arrayIndex = accountInformation.findIndex((account: IAccountInformation) => selectedAccount.accountNumber === account.accountNumber)
                    // const payload2 = {arrayIndex: arrayIndex, termChange: {cdGroup: chosenTerm.group, cdTerm: chosenTerm.term, apy: chosenTerm.apy, productCode: chosenTerm.code}}
                    // dispatch(updateGraceTermChangeByIndex(payload2))
                    const accountType = "CD"
                    const description = chosenTerm.group.toLowerCase() === "bumpupcd" ? `Bump-Up Jumbo CD ${chosenTerm.name}` : `Jumbo CD ${chosenTerm.name}`
                    const term = chosenTerm.term + " months"
                    const interestRate = +chosenTerm.apy
                    const cdGroup = chosenTerm.group
                    // const maturityDate = convertDateTommddyyyy(calculateMaturityDate(selectedAccount.creationDate, term) as string)
                    // const maturityDate = calculateMaturityDate(String(new Date(selectedAccount.graceStartDate).toLocaleDateString()), chosenTerm.term)
                    const maturityDate = calculateMaturityDate(selectedAccount.graceStartDate, chosenTerm.term)
                    const maturitySettings = {option: "Renew", termLength: term, changeProduct: description}
                    const payload = {
                        accountType: accountType, 
                        description: description, 
                        term: term,
                        interestRate: interestRate,
                        maturityDate: maturityDate,
                        maturitySettings: maturitySettings,
                        renewal: {
                            cdGroup: chosenTerm.group, cdTerm: chosenTerm.term, apy: chosenTerm.apy, productCode: chosenTerm.code
                        },
                        cdGroup: cdGroup
                    }
                    dispatch(updateGraceTermChange(payload))
                    const arrayIndex = accountInformation.findIndex((account: IAccountInformation) => selectedAccount.accountNumber === account.accountNumber)
                    const payload2 = {
                        accountType: accountType, 
                        description: description, 
                        term: term,
                        interestRate: interestRate,
                        maturityDate: maturityDate,
                        maturitySettings: maturitySettings,
                        arrayIndex: arrayIndex,
                        termChange: {cdGroup: chosenTerm.group, cdTerm: chosenTerm.term, apy: chosenTerm.apy, productCode: chosenTerm.code},
                        cdGroup: cdGroup
                    }
                    dispatch(updateGraceTermChangeByIndex(payload2))
                    setShowTermChange(false)
                }
                catch{ 
                    console.log("FAILED TO UPDATE THE PRODUCT TERM")
                    setToastMessage("ERROR", "Unable to save changes. Please try again later")
                    resetToast()
                }
            }
            else if(fundTransfer && !closeAccount){
                let payload: any
                // const payload = {transferAccount: transferAccount, transferAmount: parseInt(amountInputRef.current), transferType: addFunds ? "Add" : "Withdraw"}
                // dispatch(updateGraceSettingsOnTransferFunds(payload))
                if(transferType === "Add"){
                    payload = {
                        transferAmount: selectedAccount.gracePeriodFundTransfer.transferAmount,
                        transferType: transferAccount.accountType.toLowerCase() === "internal" ? "Internal" : "Ext-inbound",
                        fromAccountDetails: {accountId: transferAccount.id, accountNickName: transferAccount.title },
                        toAccountDetails: {accountId: selectedAccount.id, accountNickName: selectedAccount.accountTitle},
                    }
                }
                else{
                    payload = {
                        transferAmount: selectedAccount.gracePeriodFundTransfer.transferAmount,
                        transferType: transferAccount.accountType.toLowerCase() === "internal" ? "Internal" : "Ext-outbound",
                        fromAccountDetails: {accountId: selectedAccount.id, accountNickName: selectedAccount.accountTitle},
                        toAccountDetails: {accountId: transferAccount.id, accountNickName: transferAccount.title},
                    }
                }
                try{
                    const response = await createGraceFundTransfer_API(payload)
                    const index = accountInformation.findIndex((account: IAccountInformation) => account.accountNumber === selectedAccount.accountNumber)
                    const accountsPayload = {
                        arrayIndex: index,
                        transferAccountId: transferAccount.id,
                        transferId: response.data.transferId, 
                        transferAccount: `${transferAccount.title} ${transferAccount.lastFour}`, 
                        transferAmount: selectedAccount.gracePeriodFundTransfer.transferAmount,
                        transferType: transferType
                    }
                    dispatch(updateGraceFundTransferId(response.data.transferId))
                    dispatch(updatesGraceSettingsTransferFundsByIndex(accountsPayload))
                    setFundTransfer(false)
                    setShowTermChange(false)
                }
                catch{
                    console.log("UNABLE TO CREATE THE TRANSFER")
                    setToastMessage("ERROR", "Unable to save changes. Please try again later")
                    resetToast()
                }    
            }
            else if(closeAccount){
                // const payload = {transferAccount: selectedTransferAccount.label, transferAmount: selectedAccount.balance, transferType: "Withdraw" }
                // dispatch(updateGraceSettingsOnTransferFunds(payload))    
                // const date = convertDateTommddyyyy(new Date())
                const payload = {toAccountId: transferAccount.id, accountType: transferAccount.accountType.toLowerCase() === "internal" ? "Internal" : "External"}
                const notificationsIdArray: Array<string> = []
                try{
                    await closeGraceAccount_API(selectedAccount.id, payload, accountInformation)
                    for(let i = 0; i < notifications.length; i = i + 1){
                        if(notifications[i].notificationType === "GracePeriod" && notifications[i].accountId === selectedAccount.id){
                            notificationsIdArray.push(notifications[i].notificationId)
                        }        
                    }

                    for(let i = 0; i < notificationsIdArray.length; i = i + 1){
                        handleDismiss(notificationsIdArray[i])
                    }        

                    const index = accountInformation.findIndex((account: IAccountInformation) => account.accountNumber === selectedAccount.accountNumber)
                    dispatch(closeSelectedAccount())
                    // const payload2 = {arrayIndex: index}
                    dispatch(closeAccountByIndex(index))
                    setCloseAccount(false)
                    history.push("/")
                    setShowTermChange(false)
                }
                catch{
                    console.log("UNABLE TO CLOSE THE ACCOUNT")
                    setToastMessage("ERROR", "Unable to save changes. Please try again later")
                    resetToast()
                }
            }
            handleConfirmClickedRef.current = false
        }
        
    }

    /**Handles the Cancel onClick event */
    const handleOnCancel = () => {
        if(fundTransfer && !closeAccount){
            const payload = {transferAccount: "", transferAmount: 0, transferType: ""}
            dispatch(updateGraceSettingsOnTransferFunds(payload))    
        }
        else if(!fundTransfer && !closeAccount){
            setChosenTerm({code: "", name: "", accountType: "", group: "", subType: "", term: "", apy: "", preferedRate: false})
        }
    }

    /**handles the back button onClick event */
    const handleBack = () => {
        if(fundTransfer && !closeAccount){
            const payload = {transferAccount: "", transferAmount: 0, transferType: ""}
            dispatch(updateGraceSettingsOnTransferFunds(payload))    
        }
    }

    return { handleConfirmUpdate, selectedAccount, handleBack, handleOnCancel }
}