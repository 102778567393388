import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { useWindowState } from "../../Context/AccountContext/useWindowState"
import { setAccountInformationByIndex } from "../../slices/accountInformation/accountInformationSlice"
import { getAccountDetailsAsync } from "../../slices/selectedAccountSlice/selectedAccountSlice"
import { populateAccountDetails } from "../../utils/populateAccountDetails"
import { useForm } from "../../Form/useForm/useForm"
import { addOwnerFormControls } from "../../Pages/AccountOwnership/addOwnerFormControls"

/**
 * This custom hook is used for the Account Ownership page
 */
export const useAccountOwnership = () => {
    const { windowState } = useWindowState()
    const history = useHistory()
    const [ addOwner, setAddOwner ] = useState<boolean>(false)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const dispatch = useAppDispatch()
    const [formStep, setFormStep] = useState<number>(1)
    const [form, dispatchForm] = useForm('addOwnerForm', addOwnerFormControls())
    const [IRSWithholding, setIRSWithholding] = useState<"Yes" | "No" | "">("")
    const [foreignAffiliation, setForeignAffiliation] = useState<"Yes" | "No" | "">("")
    const [usingSuggestion, setUsingSuggestion] = useState<boolean>(true)

    /*Re-populate the states on a page refresh */
    useEffect(() => {
        if(selectedAccount.id === ""){
            const adjustedUrl = window.location.pathname.split('/')[2]
            if(accountInformation && accountInformation[0].id !== ""){
            const index = accountInformation.findIndex((account: any) => account.id === adjustedUrl)
                if(index >= 0){
                    const account = accountInformation[index]
                    repopulateAccountDetails(index, account)
                }
                else{
                    history.push("/")
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInformation, selectedAccount])

    /**Repopulates the account details on a page refresh */
    const repopulateAccountDetails = async (index: number, account: any) => {
        const payload = {arrayIndex: index, account: account}
        const response: any = await dispatch(getAccountDetailsAsync(payload))
        try{
            let tempAccount = populateAccountDetails(accountInformation[index], response.payload.response)
            const payload = {arrayIndex: index, account: tempAccount}
            dispatch(setAccountInformationByIndex(payload))            
        }
        catch{
            console.error()
        }
    }

    return { windowState, selectedAccount, addOwner, setAddOwner, formStep, setFormStep, form, dispatchForm,
             IRSWithholding, setIRSWithholding, foreignAffiliation, setForeignAffiliation, usingSuggestion, setUsingSuggestion }
}