import { useEffect, useRef, useState } from 'react'
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useScrollToTop } from "../../ScrollToTopHook/useScrollToTop"
// import { ILinkedExternalAccounts } from '../../../api/User/interfaces'
import { getEventPath } from '../../../utils/getEventPath'
import { IExternalAccounts } from '../../../api/User/interfaces'
import { setCheckInternalToken, setOTPPassed, updateExternalAccountNicknameByIndex } from '../../../slices/userInformation/userInformationSlice'
import { useHistory } from 'react-router-dom'
import { changeExternalAccountNickname_API } from '../../../api/User/LinkExternalAccounts'
import { useToastMessage } from '../../../Context/ToastContext/useToastContext'
import { useUserProfileContext } from '../../../Pages/UserProfile/Context/useUserProfileContext'

/**custom use hook for the ManageExternalAccounts tab found on the User Profile page */
export const useManageExternalAccounts = () => {
    useScrollToTop()
    const history = useHistory()
    const userInformation = useAppSelector((state: RootState) => state.userInformation)
    const { selectedExternalAccount, setSelectedExternalAccount, openEditName, setOpenEditName, openDeleteModal, setOpenDeleteModal,
            newNickname, setNewNickname, newNicknameRef, showDetails, setShowDetails, showLimitModal, setShowLimitModal } = useUserProfileContext()
    const [openMenu, setOpenMenu] = useState<boolean>(false)
    const [enableSave, setEnableSave] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const editTypeRef = useRef<"Nickname" | "Remove" | "">("")
    const editClickedRef = useRef<boolean>(false)
    const saveClickedRef = useRef<boolean>(false)
    const linkExternalClicked = useRef<boolean>(false)
    const OTPPassed = useAppSelector((state: RootState) => state.userInformation.OTPPassed)
    const { type, message, setToastMessage, resetToast} = useToastMessage()
    const [ showTooltip, setShowTooltip ] = useState<boolean>(false)
    const tooltipIndexRef = useRef<number | null>(null)

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("menu-wrap")) === -1){
                setOpenMenu(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**handles the onchange event for the edit account nickname */
    const handleNickname = (e: any) => {
        newNicknameRef.current = e.target.value
        setNewNickname(e.target.value)
        if(newNicknameRef.current.trim().length > 0){
            setEnableSave(true)
        }
        else{
            setEnableSave(false)
        }
    }

    /**handles the onClick event for the Save button */
    const handleSave = async () => {
        if(!saveClickedRef.current){
            saveClickedRef.current = true
            const index = userInformation.externalAccounts.findIndex((account: IExternalAccounts) => account.id === selectedExternalAccount?.id)
            if(index >= 0){
                try{
                    await changeExternalAccountNickname_API(userInformation.externalAccounts[index].id, newNickname.trim(), userInformation.externalAccounts)
                    const payload = {arrayIndex: index, nickname: newNickname.trim()}
                    dispatch(updateExternalAccountNicknameByIndex(payload))
                    setOpenEditName(false)
                    setToastMessage("SUCCESS", "Account nickname updated")
                    resetToast()
                    saveClickedRef.current = false
                }
                catch{
                    setToastMessage("ERROR", "Unable to save changes. Please try again later")
                    resetToast()
                    saveClickedRef.current = false
                }
            }
        }
    }

    /**handles the onClick event for Link external account */
    const handleLinkExternalAccount = () => {
        if(userInformation.externalAccounts.length > 2){
            setShowLimitModal(true)
        }
        else{
            linkExternalClicked.current = true
            dispatch(setOTPPassed(false))
            dispatch(setCheckInternalToken(true))
        }
    }

    /**handles the edit button click, ie: edit nickname or remove account */
    const handleEditClick = async (editType: "Nickname" | "Remove") => {
        editTypeRef.current = editType
        editClickedRef.current = true
        if(editType === "Nickname"){
            setOpenEditName(true)
            setShowDetails(false)
            
        }
        if(editType === "Remove"){
            setOpenDeleteModal(true)
            setShowDetails(true)
        }
    }

    /**monitors the success of the otpModal and sets the openEditPage state to open once the OTP passes */
    useEffect(() => {
        if(OTPPassed && linkExternalClicked.current){
            linkExternalClicked.current = false
            dispatch(setOTPPassed(false))
            history.push("/linkexternalaccount")
        }   
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OTPPassed])

    return { userInformation, openMenu, setOpenMenu, selectedExternalAccount, setSelectedExternalAccount, openEditName, setOpenEditName, 
             openDeleteModal, setOpenDeleteModal, handleNickname, enableSave, newNickname, setNewNickname, handleSave, showDetails, setShowDetails, handleLinkExternalAccount,
             showLimitModal, setShowLimitModal, handleEditClick, type, message, dispatch, showTooltip, setShowTooltip, tooltipIndexRef }
}