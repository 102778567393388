import axios from 'axios';
import { ENV, getEnvironment } from './getEnvironment';
/**
 * Global variable of the color array in pre-defined order to be used with beneficiaries
 * summary card and flow.
 * !don't change the colors or the order of the colors in the array.
 */
export const COLOR_ARRAY: Array<string> = ["#F5C367", "#CEE6FF", "#00244E", "#09BB9F", "#0A5EC0",
    "#DC735D", "#C55986", "#63518D", "#D1F7E4", "#000000"]

/**
 * A list of blocked routing numbers in sring format
 */
export const blockedRoutingNumbers = JSON.stringify([
    "021054721","021054941","021204005","022050012","021305001","075000129","051050009","135000001","011100481","121000374","121058313","121058326","125000011","321900203",
    "321980003","061000146","061041891","061050057","061051111","061057005","061111129","061736889","061902024","061987654","062052229","063053337","063057003","064054445",
    "065055553","065057001","066056661","066000109","063000199","071000301","071050432","073000338","074000201","083000593","072000290","011000015","011050023","011099071",
    "011099660","052000278","054050022","091000080","091036135","091036915","091050179","091050302","091950305","104000126","104043141","081000045","081050639","092000267",
    "101000048","101050001","101050014","101050027","101050030","101050179","101056717","101059002","101059950","101059992","101090126","102050055","103050025","121058517",
    "021001208","021050330","021052367","021054527","021054747","021054750","021054763","021054802","021080957","021081082","021081215","021081244","021081286","021081299",
    "021081529","021089602","021736010","021981195","022000266","026590798","031059567","042000437","041000014","041050035","041736304","043036002","043050020","641060263",
    "641060276","641060289","641060292","641060302","044000503","031736194","036076545","231334561","043000300","123000013","123100094","031000040","031000105","031051112",
    "031052221","114000721","113000049","084000039","084040497","064000101","064057002","111000038","111050017","111050253","111050266","111050295","111736713","111736726",
    "111736739","111736975","111736988","113050015","051000033","051036803","051090030","051090302","051900023","052050066","052050079","053020202","053050036","053900089",
    "124000313","122000166","062000190","065000210","102000199","103000240","082000138","112000011","053000206","053050049","021086508","021086537","021087413","111050279",
    "111050282","111050318","111050321","111050334","111050347","051050339","061050073","021054530","021054543","021054556","021054569","021054572","021054585","021054598",
    "021054611","021054624","021054637","021054640","021054653","021054666","021054679","021054682","021054695","021054718","011736020","655060042","021050466","021051452",
    "031054287","041055551","051736158","101050043","111050156","111736742","101736215","071736109","021051384","111736205","111736218","111736221","111736234","111736247",
    "111736250","111736263","111736276","262085863","011736114","111036170","111736946","111736959","111736043","111736056","111736069","111736072","111736085","111736098",
    "111736108","122402133","122402201"
])

interface IConfig {
    /**
     * The OKTA CLIENT ID
     */
    OKTA_CLIENT_ID: string,
    /**
     * The OKTA ISSUER ID
     */
    OKTA_ISSUER: string,
    /**
     * The redirect URL to the OKTA site
     * @deprecated
     */
    OKTA_CC_REDIRECT_URL: string,
    /**
     * The CC Site redirect URL
     */
    CC_SITE_REDIRECT_URL: string,
    /**
     * The base URL for loquate
     */
    LOQUATE_BASE_URL: string,
    /**
     * The Loquate API key
     */
    LOQUATE_API_KEY: string,
    /**
     * Email validation API KEY
     */
    BRITE_VERIFY_API_KEY: string,
    /**
     * SAML URL for Open New Account
     */
    SAML_URL: string,
    /**
     * Okta redirect host
     * @deprecated
     */
    OKTA_REDIRECT_HOST: string,
    /**
     * OKta Redirect host
     * @deprecated
     */
    OKTA_REDIRECT_ROUTE: string,
    /**
     * Enabled/Disables outside email validation.
     */
    OUTSIDE_EMAIL_VALIDATION: boolean | string;
    /**
     * A string array of blocked routing numbers
     */
    BLOCKED_ROUTING_NUMBERS: Array<string>

    /**
     * The timeout (in milliseconds) for the Email Verify API. 
     */
    EMAIL_VERIFY_TIMEOUT: number;

    /**The URL to launch Yodlee fast link */
    YODLEE_URL: string
}
/**
 * The base URL used for API calls
 */
export let CONFIG: IConfig = {
    OKTA_CLIENT_ID: "0oa6zby046NfUbW4D1d7",
    OKTA_ISSUER: "https://access-dev.creditonebank.com/oauth2/aus5p1bla13ywLISr1d7",
    OKTA_CC_REDIRECT_URL: "",
    OKTA_REDIRECT_HOST: "http://localhost:3002",
    OKTA_REDIRECT_ROUTE: "/login/callback",
    CC_SITE_REDIRECT_URL: "https://test02aem.creditone.com/transactional/authenticate",
    LOQUATE_BASE_URL: "https://api.addressy.com/Capture/Interactive",
    LOQUATE_API_KEY: "XK93-AT11-HY82-FF13",
    BRITE_VERIFY_API_KEY: "7ba1ec34-6345-4650-ab47-4949490bf630",
    SAML_URL: "https://access-dev.creditonebank.com/app/c1b-dev1_blend_1/exk8lsyp75LDouzZe1d7/sso/saml",
    OUTSIDE_EMAIL_VALIDATION: true,
    YODLEE_URL: "https://finapp.creditonebankstage.yodlee.com/authenticate/creditonebank/fastlink/",
    BLOCKED_ROUTING_NUMBERS: JSON.parse(blockedRoutingNumbers),
    EMAIL_VERIFY_TIMEOUT: 4000
} as const;
/**
 * Returns the configuration for each environment
 * @returns 
 */
export const getConfig = async (): Promise<true> => {
    if (getEnvironment() === ENV.LOCAL) {
        return Promise.resolve(true)
    }
    return new Promise((res, rej) => {
        axios.get(`${window.location.origin}/api/config`)
            .then((response: any) => {
                CONFIG = {
                    ...CONFIG,
                    ...response.data,
                    YODLEE_URL: response.data.YODLEE_URL,
                    BLOCKED_ROUTING_NUMBERS: response.data.BLOCKED_ROUTING_NUMBERS ? JSON.parse(response.data.BLOCKED_ROUTING_NUMBERS) : JSON.parse(blockedRoutingNumbers)
                }
                res(true);
            })
            .catch((err: any) => {
                res(true)
            })
    })
}

/** The base URL to BFF */
export const bffBaseUrl = (() => {
    if (getEnvironment() !== ENV.LOCAL) {
        return window.location.origin + '/savings-bff/digitalbank/savings';
    }
    else {
        return 'https://deposit-dev.creditone.com/savings-bff/digitalbank/savings'
    }
})();
/**Sets the okta issuer based on the environment */
export const getIssuer = () => CONFIG.OKTA_ISSUER;

/**Gets the okta redirectUri based on the environment */
export const getRedirect = () => {
    return CONFIG.OKTA_REDIRECT_HOST + CONFIG.OKTA_REDIRECT_ROUTE;
}
/**Gets the okta ClientId based on the environment */
export const getClidentId = () => CONFIG.OKTA_CLIENT_ID;
/**Gets the okta logout URI based on the environment */
export const getLogoutUri = (() => {
    return window.location.origin
})();
export const token = () => {
    try {
        const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage') as string);
        return oktaTokenStorage.accessToken.accessToken
    }
    catch {
        return ""
    }
}