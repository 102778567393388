import './VerificationCodeInput.scss'
import { IVerificationCodeInputProps } from './interfaces/IVerificationCodeInputProps'
import verifyMobile from '../../../../assets/verifyMobile.svg'
import verifyEmail from '../../../../assets/verifyEmail.svg'
import VerificationCode from './components/VerificationCode/VerificationCode'
import arrowLeftBlue from '../../../../assets/arrowLeftBlue.svg'
import { useVerificationCodeInput } from '../../../../useHooks/componentHooks/useVerificationCodeInput/useVerificationCodeInput'
import greenCheckmark from '../../../../assets/greenCheckmark.svg'
import Iconphone from '../../../../assets/Iconphone.svg'
import { Button, TIMER_IDS } from '../../../Button/Button'

/**Renders the six box verificaiton code input component used in one-time-password challenges */
const VerificationCodeInput = (props: IVerificationCodeInputProps) => {
    const { setStep, verifySelection, chosenFactor, factorOptions } = props
    const { windowState, setInputCode, status, validEntry, handleCancel, handleResend, codeResent, resendTimer, clearInputs, setClearInputs,
        handleVerify } = useVerificationCodeInput(setStep, factorOptions)

    return (
        <div className="verification-code-wrapper">
            {verifySelection === "sms" && <img className="image" src={verifyMobile} alt="mobile phone" />}
            {verifySelection === "email" && <img className="image" src={verifyEmail} alt="mail envelope" />}
            {verifySelection === "call" && <img className="image" src={Iconphone} alt="mobile phone" />}
            <span className="title h4">Enter verification code</span>
            {verifySelection === "sms" &&
                <>
                    <span className="sub-title regular">Enter the verification code we sent to</span>
                    <span className="sub-title bottom regular">{chosenFactor.value}.</span>
                </>
            }
            {verifySelection === "email" &&
                <>
                    <span className="sub-title regular">Enter the verification code we sent to</span>
                    <span className="sub-title bottom regular">{chosenFactor.value}.</span>
                </>
            }
            {verifySelection === "call" &&
                <>
                    <span className="sub-title regular">Enter the verification code we called to</span>
                    <span className="sub-title bottom regular">{chosenFactor.value}.</span>
                </>
            }
            <VerificationCode
                setInputCode={setInputCode}
                status={status}
                clearInputs={clearInputs}
                setClearInputs={setClearInputs}
                windowState={windowState !== "Mobile" ? "Desktop" : "Mobile"}
            />
            {!codeResent && status !== "Fail" ?
                <span className="text regular">Didn't receive the code?<span className="blue-text regular" onClick={() => handleResend(chosenFactor)}>&nbsp;Resend</span></span>
                :
                <>
                    {status !== "Fail" ?
                        <div className={windowState !== "Mobile" ? "resend-timer-box" : "resend-timer-box mobile"}>
                            <img src={greenCheckmark} alt="green checkmark circle" />
                            <div className="message-stack">
                                <span className={windowState !== "Mobile" ? "message regular" : "message small"}>We sent you a new code.</span>
                                <span className={windowState !== "Mobile" ? "message regular" : "message small"}>Request another in {resendTimer} seconds.</span>
                            </div>
                        </div>
                        :
                        <div className="fail-message-box">
                            <span className="fail-text regular">That code is incorrect or expired. Try again or get a new code.<span className="blue-text regular" onClick={() => handleResend(chosenFactor)}>&nbsp;Resend</span></span>
                        </div>
                    }
                </>
            }
            {windowState !== "Mobile" ?
                <div className="button-wrap">
                    <button className="Button btn-secondary" onClick={() => handleCancel()}>Cancel</button>
                    <Button
                        title='Verify code'
                        isDisabled={!validEntry}
                        className={validEntry ? "Button btn-primary" : "Button btn-primary inactive"}
                        id={TIMER_IDS.MFA_VERIFY}
                        onClick={() => handleVerify(chosenFactor)}
                    />
                </div>
                :
                <div className="button-wrap mobile">
                    <Button
                        title='Verify code'
                        isDisabled={!validEntry}
                        className={validEntry ? "Button btn-primary mobile" : "Button btn-primary inactive mobile"}
                        id={TIMER_IDS.MFA_VERIFY}
                        onClick={() => handleVerify(chosenFactor)}
                    />
                    <button className="Button btn-secondary mobile" onClick={() => handleCancel()}>Cancel</button>
                </div>
            }
            <div className="back-wrapper" onClick={() => setStep(1)}>
                <img src={arrowLeftBlue} alt="blue left facing chevron" />
                <span className="blue-text regular">Choose a different way to verify</span>
            </div>
        </div>
    )
}

export default VerificationCodeInput