/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef, useState } from "react"
import { IAccountInformation, IProduct } from "../../../api/getAccountInformation"
import { updateMaturitySettings_API } from "../../../api/putMaturitySettings"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { updatesMaturitySettingsCloseByIndex, updatesMaturitySettingsRenewalByIndex, updatesMaturitySettingsTransferFundsByIndex } from "../../../slices/accountInformation/accountInformationSlice"
import { addScheduledMaturityTransferId, updateMaturitySettings } from "../../../slices/selectedAccountSlice/selectedAccountSlice"
import { setSelectedAccount } from "../../../slices/selectedAccountSlice/selectedAccountSlice"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { useNotifications } from '../../../Context/NotificationContext/useNotificationContext'

/**A custom use hook for the UpdateMaturityPlan component */
export const useUpdateMaturityPlan = (chosenTerm: IProduct, setPlanChange: any, setShowTermChange: any, setChangePlanOption: any, changePlanOption: any, setTermChange: any, setManageFunds: any, setTransferType: any) => {
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const dispatch = useAppDispatch()
    const { windowState } = useWindowState()
    const isApiCalled = useRef<boolean>(false)
    const { type, message, resetToast, setToastMessage } = useToastMessage()
    const { initNotifications } = useNotifications();
    /**handles the submit changes button click */
    const handleSubmit = async () => {
        if (!isApiCalled.current) {
            isApiCalled.current = true
            if (changePlanOption === "Renew") {
                try {
                    const response = await updateMaturitySettings_API(
                        selectedAccount.id,
                        "renew",
                        chosenTerm.code,
                        selectedAccount.maturitySetting.transferId as string,
                        selectedAccount.maturitySetting.option === "Close" ? 0 : selectedAccount.maturitySetting.transferAmount as number,
                        selectedAccount.maturitySetting.transferType?.toLowerCase() as "add" | "withdraw" | "",
                        selectedAccount.maturitySetting.transferAccountType as "external" | "internal" | "",
                        accountInformation
                    )
                    let payload = {
                        term: chosenTerm.term,
                        type: changePlanOption,
                        changePlan: changePlanOption === "Renew" ? `${chosenTerm.group} ${chosenTerm.name.toLowerCase()}` : "",
                        transferAmount: selectedAccount.maturitySetting.option === "Close" ? 0 : selectedAccount.maturitySetting.transferAmount as number,
                        changePlanCode: chosenTerm.code
                    }
                    dispatch(updateMaturitySettings(payload))
                    const index = accountInformation.findIndex((account: IAccountInformation) => account.accountNumber === selectedAccount.accountNumber)
                    const accountsPayload = {
                        arrayIndex: index,
                        term: chosenTerm.term,
                        type: changePlanOption,
                        changePlan: changePlanOption === "Renew" ? `${chosenTerm.group} ${chosenTerm.name.toLowerCase()}` : "",
                        transferAmount: selectedAccount.maturitySetting.option === "Close" ? 0 : selectedAccount.maturitySetting.transferAmount as number,
                        changePlanCode: chosenTerm.code
                    }
                    initNotifications();
                    dispatch(updatesMaturitySettingsRenewalByIndex(accountsPayload))
                    setToastMessage("SUCCESS", "Maturity plan updated")
                    setPageState()
                }
                catch {
                    console.log("FAILED TO UPDATE MATURITY SETTINGS")
                    setToastMessage("ERROR", "Unable to save changes. Please try again later.")
                    resetToast()
                    resetApiRef()
                }
            }
            else if (changePlanOption === "Close") {
                try {
                    const response = await updateMaturitySettings_API(
                        selectedAccount.id,
                        "close",
                        "",
                        selectedAccount.maturitySetting.closedAccountId as string,
                        selectedAccount.balance,
                        "",
                        selectedAccount.maturitySetting.transferAccountType as "external" | "internal" | "",
                        accountInformation
                    )
                    const index = accountInformation.findIndex((account: IAccountInformation) => account.accountNumber === selectedAccount.accountNumber)
                    const accountsPayload = {
                        arrayIndex: index,
                        transferAccount: selectedAccount.maturitySetting.closedAccountName
                    }
                    initNotifications();
                    dispatch(updatesMaturitySettingsCloseByIndex(accountsPayload))
                    setToastMessage("SUCCESS", "Maturity plan updated")
                    setPageState()
                }
                catch {
                    console.log("ERROR UPDATING THE MATURITY SETTINGS TO CLOSE")
                    setToastMessage("ERROR", "Unable to save changes. Please try again later.")
                    resetToast()
                    resetApiRef()
                }

            }
            else if (changePlanOption === "") {
                try {
                    const response = await updateMaturitySettings_API(
                        selectedAccount.id,
                        "transfer",
                        selectedAccount.maturitySetting.changeProductCode as string,
                        selectedAccount.maturitySetting.transferId as string,
                        selectedAccount.maturitySetting.transferAmount as number,
                        selectedAccount.maturitySetting.transferType?.toLowerCase() as "add" | "withdraw",
                        selectedAccount.maturitySetting.transferAccountType as "external" | "internal" | "",
                        accountInformation
                    )
                    dispatch(addScheduledMaturityTransferId(response.data.orderId))
                    const index = accountInformation.findIndex((account: IAccountInformation) => account.accountNumber === selectedAccount.accountNumber)
                    const accountsPayload = {
                        transferId: selectedAccount.maturitySetting.transferId,
                        arrayIndex: index,
                        transferAccount: selectedAccount.maturitySetting.transferAccount,
                        transferAmount: selectedAccount.maturitySetting.transferAmount,
                        transferType: selectedAccount.maturitySetting.transferType,
                        scheduledTransferId: response.data.orderId
                    }
                    initNotifications();
                    dispatch(updatesMaturitySettingsTransferFundsByIndex(accountsPayload))
                    setToastMessage("SUCCESS", "Maturity plan updated")
                    setPageState()
                }
                catch {
                    console.log("ERROR UPDATING THE MATURITY FUND TRANSFER")
                    setToastMessage("ERROR", "Unable to save changes. Please try again later.")
                    resetToast()
                    resetApiRef()
                }
            }
        }
    }

    /**resets the api called ref when a call is attempted but has failed, allows the user to try again */
    const resetApiRef = () => {
        setTimeout(() => {
            isApiCalled.current = false
        }, 4000)
    }

    /**Set the states that control the page flow */
    const setPageState = () => {
        setPlanChange(false)
        setShowTermChange(false)
        setTermChange(false)
        setManageFunds(false)
        setChangePlanOption("")
    }

    /**Handles the cancel button onClick */
    const handleCancel = () => {
        const index = accountInformation.findIndex((account: IAccountInformation) => account.accountNumber === selectedAccount.accountNumber)
        dispatch(setSelectedAccount(accountInformation[index]))
        setPlanChange(false)
        setTermChange(false)
        setShowTermChange(false)
        setChangePlanOption("")
        setManageFunds(false)
        setTransferType("")
    }

    return { selectedAccount, handleSubmit, windowState, handleCancel, type, message }
}

