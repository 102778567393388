import axios from "axios"
import { bffBaseUrl, token } from "../utils/globalVariables"
import { IAccountInformation } from "./getAccountInformation"

/**
 * API call for post memo
 * @param transaction the transaction that will be edited
 * @param memo new memo to change to
 */
export const postMemo_API = (accountId: string, transactionId: string, memo: string, index: number, accountInformation: Array<IAccountInformation>) => {
    let id = ""

    const arrayIndex = accountInformation.findIndex((account: IAccountInformation) => account.id === accountId)
    if(arrayIndex >= 0 ){
        id = accountInformation[arrayIndex].id
    }
    
   return axios.patch(`${bffBaseUrl}/accounts/${id}/transactions/${transactionId}`,
        {
            memo: memo,
            index: index
        },
        {
            headers: {
                Authorization: `Bearer ` + token,
                'Content-Type': 'application/json'
            }
        }
   )
}