import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postMemo_API } from '../../api/postMemo';
import { RootState } from "../../app/store";
import { IAccountInformation } from '../../api/getAccountInformation';

export interface ISelectedTransaction {
    selectedTransaction: {
        id: string;
        memo: string;
        description: string;
        amount: number;
        effectiveDate: any;
        payerName: string;
        type: string;
        institution: string;
        availableNow: number;
        accountType: string;
        accountNumber?: string;
        holdAmount: number;
        holdAmountReason: string;
        currentBalance: number;
        status: "On Hold" | "Pending" | "Confirmed";
        availableOn: string;
        index: number
    }
}

/**Creates the interface for  */
const initialState: ISelectedTransaction = {
    selectedTransaction: {
        id: "",
        memo: "",
        description: "",
        amount: 0,
        effectiveDate: "",
        payerName: "",
        type: "",
        institution: "",
        availableNow: 0,
        accountType: "",
        accountNumber: "",
        holdAmount: 0,
        holdAmountReason: "",
        currentBalance: 0,
        status: "Confirmed",
        availableOn: "",
        index: 0
    }            
}

/**API Call to create or update the transaction memo */
export const patchTransactionMemoAsync = createAsyncThunk(
    "selectedTransaction/patchSelectedTransactions_API",
    async(apiData: {accountId: string, transactionId: string, memo: string, index: number, accountInformation: Array<IAccountInformation>}) => {
        const response = await postMemo_API(apiData.accountId, apiData.transactionId, apiData.memo, apiData.index, apiData.accountInformation)

        return response.data
    }
)

/**The selected transaction history slice */
export const selectedTransactionSlice = createSlice({
    name: "selectedTransaction",
    initialState,
    reducers: {
        /**Sets the selected transaction, action is transaction: ITransactionHistoryData and index: number */
        setSelectedTransaction: (state, action) => {
            state.selectedTransaction = action.payload.transaction
        },
        /**Updates the memo on a selected transaction, action is transaction: ITransactionHistoryData and index: number*/
        updateSelectedTransactionMemo: (state, action) => {
            state.selectedTransaction.memo = action.payload
        },
        /**Clear the selected transaction */
        clearSelectedTransaction: (state) => {
            state.selectedTransaction = initialState.selectedTransaction
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(patchTransactionMemoAsync.pending, (state) => {})
        .addCase(patchTransactionMemoAsync.fulfilled, (state) => {})
        .addCase(patchTransactionMemoAsync.rejected, (state) => {
        })
    }
})

export const selectedTransaction = (state: RootState) => state.selectedTransaction.selectedTransaction
export const { setSelectedTransaction, updateSelectedTransactionMemo, clearSelectedTransaction } = selectedTransactionSlice.actions

export default selectedTransactionSlice.reducer