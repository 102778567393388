import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { IAccountInformation, downloadAccountStatement } from "../../../api/getAccountInformation";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useToastMessage } from "../../../Context/ToastContext/useToastContext";
import { downloadDocuments } from "../../../utils/downloadDocuments";

 /**
 * The useHook holds all logic, states, and routing for the Summary Statement Card component
 */ 
export const useStatementsCard = (selectedAccount: IAccountInformation) => {  
    const history = useHistory()
    const gotStatements = useAppSelector((state: RootState) => state.selectedAccount.gotStatements)
    const { setToastMessage, resetToast } = useToastMessage()
    const downloadClickedRef = useRef<boolean>(false)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)


    /**handle the onClick event and downloads the account statement document */
    const handleStatementDownload = async (id: string) => {
       if(!downloadClickedRef.current){
            downloadClickedRef.current = true
           try{
               const response = await downloadAccountStatement(selectedAccount.id, id, accountInformation)
               downloadDocuments(response.data, selectedAccount.accountNumber, "Statement", "")
           }
           catch{
               console.log("FAILED TO DOWNLOAD THE ACCOUNT STATEMENT")
               setToastMessage("ERROR", "Unable to download document. Please try again later")
               resetToast()
           }
           downloadClickedRef.current = false
       } 
    }    

    return { history, gotStatements, handleStatementDownload }
}; 
