import { IAccountAgreements } from '../../../api/getAccountInformation'
import MobileDocumentCenterFilter from '../../../Pages/Documents/MobileDocumentsPage/components/MobileDocumentCenterFilter/MobileDocumentsFilter'
import MobileDocumentsDisplayHeader from '../../../Pages/Documents/MobileDocumentsPage/components/MobileDocumentsDisplayHeader/MobileDocumentsDisplayHeader'
import { IMobileDocumentAccountAgreementsProps } from './interfaces/IMobileDocumentAccountAgreementsProps'
import './MobileDocumentsAccountAgreement.scss'
import blueDocument from '../../../assets/blueDocument.svg'
// import { convertDateTommddyyyy } from '../../../utils/DateUtils'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { useToastMessage } from '../../../Context/ToastContext/useToastContext'
import { useRef } from 'react'
import { downloadDocuments } from '../../../utils/downloadDocuments'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'
import warning from '../../../assets/warning.svg'
import emptyStatements from '../../../assets/emptyStatements.svg'
import { downloadSignedAgreement_API } from '../../../api/termsAndConditions'

/**The component displays the Document Center mobile version Account Agreements page */
const MobileDocumentsAccountAgreement = (props: IMobileDocumentAccountAgreementsProps) => {
    const { selectedAccount, accountInformation, selectedPage, setSelectedPage, handleSave, setSelectedDate } = props
    const gotAgreements = useAppSelector((state: RootState) => state.selectedAccount.gotAgreements)
    const { message, type, setToastMessage, resetToast } = useToastMessage()
    const downloadClickedRef = useRef<boolean>(false)

    /**handle the onClick event and downloads the account statement document */
    const handleStatementDownload = async (id: string) => {
        if(!downloadClickedRef.current){
            downloadClickedRef.current = true
            try{
                const response = await downloadSignedAgreement_API(selectedAccount.id, id, accountInformation)
                downloadDocuments(response.data, selectedAccount.accountNumber, "Agreement", "")
            }
            catch{
                console.log("FAILED TO DOWNLOAD THE ACCOUNT AGREEMENT")
                setToastMessage("ERROR", "Unable to download document. Please try again later")
                resetToast()  
            }
            downloadClickedRef.current = false
        }
    }

    return(
        <div className="mobile-documents-account-agreement">
            <MobileDocumentsDisplayHeader selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
            {gotAgreements !== "Loading" ?
                <div className="mobile-account-agreement-wrapper">
                    <div className="filter-wrap">
                        <MobileDocumentCenterFilter 
                            selectedAccount={selectedAccount} 
                            accountInformation={accountInformation} 
                            selectedPage={selectedPage}
                            handleSave={handleSave}
                            setSelectedDate={setSelectedDate}
                        />
                    </div>
                    {gotAgreements === "Success" ?
                        <>
                        {message !== "" && <ToastMessageDB type={type} message={message} />}
                        {selectedAccount.accountAgreements.length > 0 &&
                            <div className="agreements-wrapper">
                                {selectedAccount.accountAgreements.map((account: IAccountAgreements, index: number) => {
                                    return(
                                        <div key={index} className="account-row" onClick={() => handleStatementDownload(account.id)}>
                                            <img src={blueDocument} alt="blue colored document" />
                                            <span className="account-details regular">{account.title}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        </>
                        :
                        <div className="message-wrapper">
                            <img className="message-image" src={warning} alt="no statements" />
                            <span className="header-text margin h4">Unable to load data</span>
                            <span className="sub-text regular">Please wait a moment and try again.</span>
                        </div>
                    }
                </div>

                :
                <div className="loading-box" />
            }
        </div>
    )
}

export default MobileDocumentsAccountAgreement